import { actionApi, ajaxBaseConfig } from '@/redux/utils.js'
import { ajax } from '@/redux/ajax.js'
import urls from '@/api/urls'
import qs from 'query-string'
import { map, catchError } from 'rxjs/operators'
import { of } from 'rxjs'
const uiActions = iceStarkData.store.get('commonAction')?.ui
import { store } from '@/index'

/**
 * @param {string} str
 */
function replaceSlash(str) {
  return str.replace(/([^:]\/)\/+/g, '$1')
}

/**
 * @typedef HttpArgs
 * @prop {string} url
 * @prop {string=} baseURL
 * @prop {string=} method
 * @prop {{[key:string]:any}=} params
 * @prop {{[key:string]:any}=} data
 * @prop {{[key:string]:any}=} headers
 * @prop {boolean=} isFetch
 */

/**
 * @param {HttpArgs} args
 */
function Http(args) {
  actionApi()

  const { url, baseURL, method = 'GET', params, data, headers = {}, isFetch = false } = args

  const config = {
    ...ajaxBaseConfig,
    headers: { ...ajaxBaseConfig.headers, ...headers },
    method: method.toUpperCase(),
    url: `${urls.baseUrl}/${url}`
  }

  if (baseURL) {
    config.url = `${baseURL}/${url}`
  }

  config.url = replaceSlash(config.url)

  if (params && typeof params === 'object') {
    /** @type {URL} */
    let currentUrl
    try {
      currentUrl = new URL(config.url)
    } catch {
      currentUrl = new URL(replaceSlash(`${location.origin}/${config.url}`))
    }
    const queryStr = qs.stringify(
      {
        ...qs.parse(currentUrl.search),
        ...params
      },
      { encode: false }
    )
    config.url = `${currentUrl.origin}${currentUrl.pathname}?${queryStr}`
  }

  if (config.method !== 'GET' && data) {
    config.body = data
  }

  if (isFetch) {
    delete config.headers['Content-Type']
    return fetch(config.url, config).then(async res => {
      if (res.ok) {
        return res
      } else {
        try {
          if (config.method !== 'GET' && res.headers.get('Content-Type')?.startsWith('application/json')) {
            store.dispatch(uiActions.notification(await res.json()))
          }
        } catch (err) {}
        
        return Promise.reject(res)
      }
    })
  }

  return new Promise((resolve, reject) => {
    const obs$ = ajax(config).pipe(
      map(res => resolve(res.response)),
      catchError(error => {
        if (config.method !== 'GET') {
          store.dispatch(uiActions.notification(error.xhr.response))
        }
        return of(reject(error.xhr.response))
      })
    )
    obs$.subscribe()
  })
}

export default Http
