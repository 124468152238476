import React, { Component } from 'react'
import { reduxForm } from 'redux-form'
import { Dialog, ListSelector } from 'tyb'

class TableHeaderSettingModal extends Component {
  constructor(props) {
    super(props)

    const initList = props.eseData.filter(item => item.titleShow === true).map(item => item.value)
    this.state = {
      list: initList,
      initList,
      result: {}
    }
  }

  compareFieldArray = (arr1, arr2) => {
    const addDictId = []
    const removeDictId = []

    arr2.forEach(a2 => {
      if (!arr1.includes(a2)) {
        addDictId.push(a2)
      }
    })

    arr1.forEach(a1 => {
      if (!arr2.includes(a1)) {
        removeDictId.push(a1)
      }
    })

    return {
      addDictId,
      removeDictId
    }
  }

  handleSubmit() {
    const { result = {}, list = [] } = this.state
    const { handleConfigTableTitle, handleVisible, dictDataType = 'PRODUCT' } = this.props
    const { addDictId = [], removeDictId = [] } = result

    handleConfigTableTitle({
      dictDataType,
      removeDictId,
      addDictId,
      callback: () => {
        handleVisible()
      }
    })
  }

  render() {
    let { visible, handleVisible, eseData = [] } = this.props
    let { list = [], initList = [] } = this.state

    return (
      <Dialog
        className=""
        title="表头设置"
        visible={visible}
        style={{ width: '770px' }}
        onOk={this.handleSubmit.bind(this)}
        onCancel={() => {
          handleVisible()
        }}>
        <ListSelector
          className="fields"
          label="选择表头字段"
          list={eseData}
          value={list}
          prepareItemTextFn={item => {
            return <span style={{ cursor: 'default' }}>{item.text}</span>
          }}
          selectedItemTextFn={item => {
            return <span style={{ cursor: 'default', color: '#444' }}>{item.text}</span>
          }}
          onChange={value => {
            const result = this.compareFieldArray(initList, value)

            this.setState({
              list: value,
              result
            })
          }}
        />
      </Dialog>
    )
  }
}

const _TableHeaderSettingModal = reduxForm({
  form: 'TableHeaderSettingModalForm'
})(TableHeaderSettingModal)

export default _TableHeaderSettingModal
