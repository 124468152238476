import React from 'react';
import {connect} from 'react-redux';
import {DataTable, Pager, Button, SearchInput} from 'tyb';
const {loadBrandPagerList, disabledOrEnabledBrand, updataBrandSearchKey} = iceStarkData.store.get('commonAction')?.brand;
import {handSortOrder} from 'ucode-utils';
import { CreateBrandForm } from '../../index';


@connect (
  state => {
    return {
      ...state.brand,
      permissionIds: state.users.permissionIds
    };
  },
  {
    updataBrandSearchKey,
    loadBrandPagerList: loadBrandPagerList.REQUEST,
    disabledOrEnabledBrand: disabledOrEnabledBrand.REQUEST,
  }
)
class BrandTable extends React.Component {
  constructor (props) {
    super (props);
    this.state = {
      brandList: [],
      brandColumns: [
        {
          key: 'id',
          dataIndex: 'name',
          title: '产品名称/ID',
          headerRender: col => this.tableHeadSort (col),
          render: (value, record) => {
            return (
              <div>
                <div>{value}</div>
                <div>{record.id}</div>
              </div>
            );
          },
        },

        {
          key: 'status',
          dataIndex: 'status',
          title: '操作',
          render: (value, record) => {
            return (
              <div className="table-operate-btn" style={{color: '#006EFF'}}>
                {this.props.permissionIds.includes('data.basicData.product.edit') && <span onClick={() => { this.setState({ showbrandCreateForm: true, dataId: record.id });}}>编辑</span>}
                {this.props.permissionIds.includes('data.basicData.product.status') && <span onClick={() => { this.props.disabledOrEnabledBrand({ id: record.id, status: value == 'ENABLE' ? 'DISABLE' : 'ENABLE' }); }} >{value == 'ENABLE' ? '禁用' : '启用'}</span>}
              </div>
            );
          },
        },
      ],
      loadbrandListPage: {},
      showbrandCreateForm: false,
      dataId: ''
    };
  }

  componentDidMount () {
    this.props.loadBrandPagerList ();
  }

  handleSort = (curOrder, key) => {
    const {loadBrandPagerList, brandPageParam} = this.props;
    const newOrder = handSortOrder (curOrder);
    loadBrandPagerList ({
      brandPageParam: {
        ...brandPageParam,
        page: 0,
        sort: `${key},${newOrder}`,
      },
    });
    this.setState ({sortId: newOrder});
  };

  // 排序 sort
  tableHeadSort = col => {
    return (
      <DataTable.SortableColumnHeader
        className="text-overflow"
        order={this.state.sortId}
        sortKey={col.key}
        onSort={() => { this.handleSort (this.state.sortId, col.key); }}
      >
        {col.title}
      </DataTable.SortableColumnHeader>
    );
  };
  changePage = (value, tag) => {
    const { loadBrandPagerList, brandPageParam } = this.props;
    const obj = tag == 'perpage' ? { ...brandPageParam, page: 0, size: value || 20 } : {...brandPageParam, page: value};
    loadBrandPagerList({brandPageParam: {...obj} });
  };

  render () {
    const { showbrandCreateForm, brandColumns,  dataId,  } = this.state;
    const {brandPageList = [], brandPageListLoading, loadBrandPagerList, brandSearchKey, updataBrandSearchKey, brandPageParam = {}} = this.props;
    return (
      <div className={`brandtable-container ${brandPageList.length > 0 ? 'table-tr-height50' : 'table-tr-middle'}`}>

        <div style={{overflow: 'hidden'}}>
        {this.props.permissionIds.includes('data.basicData.product.add') && <Button onClick={() => { this.setState({showbrandCreateForm: true, dataId: ''}); }} > 新增品牌 </Button>}
        <div className="search-input-block">
          {/* {BrandPageList.length > 0 && */}
          <SearchInput
            onChange={value => { updataBrandSearchKey({ brandSearchKey: value }); }}
            onSearch={value => { loadBrandPagerList({ brandPageParam: { ...brandPageParam, page: 0 }, brandSearchKey: value}); }}
            value={ brandSearchKey }
            placeholder="输入品牌名称/ID搜索"
          />
          {/* } */}
        </div>
       </div>
        <DataTable
          columns={brandColumns}
          data={brandPageList}
          rowKey="id"
          rowClassName={(record) =>  record.status == 'DISABLE' ? 'table-gray-row' : ''}
          loading={brandPageListLoading}
          style={{
            width: '100%',
            maxWidth: '100%',
            border: '1px solid #e2e2e2',
            borderBottom: 'none',
            marginTop: '10px',
          }}
          messageRender={({data, error}) => {
            if (error != null) {
              return error.msg;
            }
            if (data == null || data.length === 0) {
              return <div className="text-weak"> 暂无品牌{ this.props.permissionIds.includes('data.basicData.product.add') ? "，您可以点击左上角" : null }
                {this.props.permissionIds.includes('data.basicData.product.add') && <span style={ { color: '#006EFF' } }
                onClick={() => { 
                  this.setState({showbrandCreateForm: true, dataId: ''});
                }}
                >新增品牌</span>}
              </div>;
            }
          }}
        />
        {brandPageList.length > 0 &&
          <Pager
            total={brandPageParam.totalElements}
            current={brandPageParam.page}
            perPageCount={brandPageParam.size}
            onPageChange={value => {
              this.changePage (value, 'page');
            }}
            onPerPageChange={value => {
              this.changePage (value, 'perpage');
            }}
          />}
       {showbrandCreateForm &&  <CreateBrandForm
          dataId={dataId}
          showbrandCreateForm={showbrandCreateForm}
          handleCancel={() => { this.setState({ showbrandCreateForm: false });}}
          {...this.props} />}
      </div>
    );
  }
}

export default BrandTable;
