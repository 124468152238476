import React from "react";

const SvgAddIcon = props => (
  <svg width={12} height={12} {...props}>
    <defs>
      <path id="add_icon_svg__a" d="M0 0h12v12H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="add_icon_svg__b" fill="#fff">
        <use xlinkHref="#add_icon_svg__a" />
      </mask>
      <path
        d="M3.545 11.455h1.637a.273.273 0 0 1 0 .545H3.545a.273.273 0 0 1 0-.545zM.273 6.545c.15 0 .272.123.272.273v1.637a.273.273 0 0 1-.545 0V6.818c0-.15.122-.273.273-.273zm0-3.272c.15 0 .272.122.272.272v1.637a.273.273 0 0 1-.545 0V3.545c0-.15.122-.272.273-.272zM1.909.545H.545V1.91a.273.273 0 0 1-.545 0V.273C0 .123.122 0 .273 0h1.636a.273.273 0 0 1 0 .545zM1.91 12H.273A.273.273 0 0 1 0 11.727v-1.636a.273.273 0 0 1 .545 0v1.364H1.91a.273.273 0 0 1 0 .545zM5.182.545H3.545a.273.273 0 0 1 0-.545h1.637a.273.273 0 0 1 0 .545zm2.966 4.91a.545.545 0 1 1 0 1.09H6.511v1.637a.545.545 0 1 1-1.09 0V6.545H3.783a.545.545 0 0 1 0-1.09H5.42V3.818a.545.545 0 1 1 1.091 0v1.637h1.637zm.307-4.91H6.818a.273.273 0 0 1 0-.545h1.637a.273.273 0 0 1 0 .545zm0 11.455H6.818a.273.273 0 0 1 0-.545h1.637a.273.273 0 0 1 0 .545zm3.272-9.818a.273.273 0 0 1-.272-.273V.545H10.09a.273.273 0 0 1 0-.545h1.636c.15 0 .273.122.273.273v1.636c0 .15-.122.273-.273.273zm0 3.273a.273.273 0 0 1-.272-.273V3.545a.273.273 0 0 1 .545 0v1.637c0 .15-.122.273-.273.273zm0 3.272a.273.273 0 0 1-.272-.272V6.818a.273.273 0 0 1 .545 0v1.637c0 .15-.122.272-.273.272zm0 3.273h-1.636a.273.273 0 0 1 0-.545h1.364V10.09a.273.273 0 0 1 .545 0v1.636c0 .15-.122.273-.273.273z"
        fill="#050101"
        mask="url(#add_icon_svg__b)"
      />
    </g>
  </svg>
);

export default SvgAddIcon;
