import React from 'react';
import { connect } from 'react-redux';
import queryString from "query-string";
import { DataTable, Pager, Button, SearchInput, notification, } from 'tyb';
import { handSortOrder } from 'ucode-utils';
const { loadProductPageList, enabledOrDisabledProducts, exportProduct, updataProductSearchKey, editProductPack } = iceStarkData.store.get('commonAction')?.products;
const { loadBrandSelections, resetBrandSelections } = iceStarkData.store.get('commonAction')?.brand;
const { setNavBar } = iceStarkData.store.get('commonAction')?.ui;
import './../../../index.scss';
import { DownLoadAnimate } from 'ucode-components';
import ProductModal from '../../ProductModal';
import TableHeaderSettingModal from '@/pages/BaseManagement/components/TableHeaderSettingModal'
const { fieldAssignEseQuery, configTableTitle } = iceStarkData.store.get('commonAction')?.fieldAssign;

@connect(
  state => {
    return {
      ...state.products,
      ...state.brand,
      permissionIds: state.users.permissionIds || [],
      eseData: state.fieldAssign.eseData,
    };
  },
  {
    setNavBar,
    updataProductSearchKey,
    resetBrandSelections,
    loadProductPageList: loadProductPageList.REQUEST,
    loadBrandSelections: loadBrandSelections.REQUEST,
    enabledOrDisabledProducts: enabledOrDisabledProducts.REQUEST,
    exportProduct: exportProduct.REQUEST,
    editProductPack: editProductPack.REQUEST,
    handleConfigTableTitle: configTableTitle.REQUEST,
    getEseData: fieldAssignEseQuery.REQUEST,
  }
)
class ProductTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      sortId: 'desc',
      productColumns: [
        {
          key: 'id',
          dataIndex: 'name',
          title: '产品名称/ID',
          render: (value, record) => {
            return (
              <div>
                <div>{value}</div>
                <div>{record.id}</div>
              </div>
            );
          },
        },
        {
          key: 'code',
          dataIndex: 'code',
          title: '产品编码',
        },
        {
          key: 'brandId',
          dataIndex: 'brandName',
          title: '品牌',
        },
        {
          key: 'salesPrice',
          dataIndex: 'salesPrice',
          title: '售价',
          render: (value, record) => {
            return (<div> {value}元 </div>);
          },
        },
        {
          key: 'usefulLife',
          dataIndex: 'usefulLife',
          title: '保质期',

          render: (value, record) => {
            const lifeUnitFilter = { MONTH: '个月', YEAR: '年', DAY: '天' };
            return (<div> {value}{lifeUnitFilter[record.lifeUnit]} </div>);
          },
        },
        {
          key: 'categoryName',
          dataIndex: 'categoryName',
          title: '所属分类',
          render: (value, record) => { return (<div> {value} {record.secondCategoryName} </div>); },
        },
        {
          key: 'packSpec',
          dataIndex: 'packSpec',
          title: '产品包装',
          render: (value, record) => {
            return <div>
              {value ? value.indexOf('[') >= 0 ? Array.isArray(JSON.parse(value)) ? JSON.parse(value || '[]').reverse().map((item, index) => {

                if (index != JSON.parse(value).length - 1) {
                  return <span key={index} style={{ marginRight: 10, display: 'inline-block', fontSize: 12, color: '#666' }}>1{item.name}{item.subNum}{[...JSON.parse(value)].reverse()[index + 1] ? [...JSON.parse(value)].reverse()[index + 1].name : ''}</span>
                }
              })
                : '' : '' : ''}
            </div>

          },
        },
        {
          key: 'status',
          dataIndex: 'status',
          title: '操作',
          render: (value, record) => {
            return (
              <div className="table-operate-btn" style={{ color: '#006EFF' }}>
                {this.props.permissionIds.includes('data.basicData.product.edit') && <span
                  onClick={() => {
                    this.props.resetBrandSelections();
                    this.jumpPage(record.id);
                  }}
                >
                  编辑
                </span>}
                {this.props.permissionIds.includes('data.basicData.product.status') && <span onClick={() => {
                  this.props.enabledOrDisabledProducts({ id: record.id, status: value == 'ENABLE' ? 'DISABLE' : 'ENABLE' });
                }}>
                  {value == 'ENABLE' ? '禁用' : '启用'}
                </span>}
                <span
                  onClick={(e) => this.productModalShow(true, record)}
                >
                  包装
                </span>
              </div>
            );
          },
        },
      ],
      animateShow: 0,
      productModal: {
        show: false,
        data: {},
      },
      THSVisible: false,
    };
  }

  componentDidMount() {
    const { newOrder } = this.props.productPageParam;
    this.props.loadProductPageList();
    this.props.loadBrandSelections({ status: 'ENABLE' });
    this.setState({ sortId: newOrder || 'desc' })
    this.props.getEseData({ dictDataType: "PRODUCT" });
  }

  // 分页切换
  changePage = (value, tag) => {
    const { loadProductPageList, productPageParam } = this.props;
    const obj = tag == 'perpage' ? { ...productPageParam, page: 0, size: value || 20 } : { ...productPageParam, page: value };
    loadProductPageList({ productPageParam: obj, });
  };

  tableHeadFilter = (param = {}) => {
    const { list = [], titleContent = '', value = '', filterFun } = param;
    return (
      <DataTable.FilterableColumnHeader
        list={list}
        value={value}
        onFilter={value => {
          filterFun && filterFun(value);
        }}
      >
        {titleContent}
      </DataTable.FilterableColumnHeader>
    );
  };
  handleSort = (curOrder, key) => {
    const { loadProductPageList, productPageParam } = this.props;
    const newOrder = handSortOrder(curOrder);

    loadProductPageList({
      productPageParam: {
        ...productPageParam,
        page: 0,
        sort: `${key},${newOrder}`,
        newOrder
      },
    });

    this.setState({ sortId: newOrder });
  };

  // 排序 sort
  tableHeadSort = col => {
    return (
      <DataTable.SortableColumnHeader
        className="text-overflow"
        order={this.state.sortId}
        sortKey={col.key}
        onSort={() => {
          this.handleSort(this.state.sortId, col.key);
        }}
      >
        {col.title}
      </DataTable.SortableColumnHeader>
    );
  };
  tableHeadInit = () => {
    const productColumns = [...this.state.productColumns];

    const dynamicColumns = this.props.eseData.filter(it => it.titleShow).map(it => {
      return {
        key: it.code,
        dataIndex: it.code,
        title: it.name,
        render: (value, record) => {
          let data = []
          try {
            data = JSON.parse(record.data)
          } catch(err) {}
          if (!data || typeof data !== 'object' || !data.length) {
            return
          }
          const target = data.find(t => t.code === it.code)
          if (!target) {
            return
          }
          if (it.dictType === 'PICTURE') {
            return target.value && <img style={{width:'60px',height:'60px',objectFit:'contain'}} src={target.value} />
          }
          return <div>{target.value}</div>
        }
      }
    })

    productColumns.splice(productColumns.length - 1, 0, ...dynamicColumns)

    return productColumns.map(column => {
      if (column.key === 'id') {
        return {
          ...column,
          headerRender: col => this.tableHeadSort(col),
        };
      } else if (column.key === 'brandId') {
        return {
          ...column,
          headerRender: () => {
            const { loadProductPageList, productPageParam, brandSelecList, } = this.props;
            const valueNumberArr = [];
            if (productPageParam[column.key]) {
              productPageParam[column.key].split(',').map(v => valueNumberArr.push(Number(v)));
            }

            return this.tableHeadFilter({
              list: brandSelecList || [],
              titleContent: '品牌',
              value: valueNumberArr,
              filterFun: value => {
                const newValue = value.join(',');
                loadProductPageList({ productPageParam: { ...productPageParam, [column.key]: newValue == 0 ? '' : newValue, page: 0, }, });
              },
            });
          },
        };
      } else {
        return column;
      }
    });
  };
  jumpPage = (id = '') => {
    const { setNavBar, history } = this.props;
    setNavBar(id ? '编辑产品' : '新增产品');
    history.push(id ? `databasicData/dataproduct/edit?${queryString.stringify({ id })}` : 'databasicData/dataproduct/create');
  };
  toexportProduct = () => {

    this.props.exportProduct({
      // callback: () => {

      //   this.setState({ animateShow: this.state.animateShow + 1 })
      // }
    })

    notification.success('导出成功');
  }




  //编辑产品弹窗
  productModalShow = (show, data, e) => {
    let newArray = {
      show: show,
      data: data ? data : {},

    }
    this.setState({ productModal: newArray })
  }
  //子组件弹窗用修改奖项
  changeAwardData = (data) => {


    let newdata = {
      id: data.id,
      pack: JSON.stringify(data.pack.map((item, index) => { return { ...item, level: index + 1, used: data.used.value == item.name ? true : false } }))
    }
    this.props.editProductPack({
      data: newdata, callback: () => {

        this.productModalShow(false);
      }
    })

  }










  render() {
    const { productModal } = this.state;
    const { productPageList, productSearchKey, productPageParam, productPageLoad, exportProduct, updataProductSearchKey, loadProductPageList } = this.props;
    return (
      <div className={`producttable-container  ${productPageList.length > 0 ? 'table-tr-height50' : 'table-tr-middle'}`}>
        {this.props.permissionIds.includes('data.basicData.product.add') && <Button style={{ marginRight: '10px' }} onClick={() => { this.jumpPage(); }} > 新增产品 </Button>}
        <Button onClick={() => { this.setState({ THSVisible: true }) }} style={{ marginRight: '10px' }}>表头设置</Button>
        {this.props.permissionIds.includes('data.basicData.product.group') && <Button className="mr-10 weak-button" onClick={() => { this.props.history.push('/databasicData/dataproduct/category') }}>产品分类</Button>}
        <Button className="weak export" onClick={() => { this.toexportProduct(); }}>导出</Button>
        <div className="search-input-block">
          <SearchInput
            onChange={value => { updataProductSearchKey({ productSearchKey: value }); }}
            onSearch={value => { loadProductPageList({ productPageParam: { ...productPageParam, page: 0 }, productSearchKey: value }); }}
            value={productSearchKey}
            placeholder="输入名称/编码/ID搜索"
          />
        </div>

        <DataTable
          // columns={this.state.productColumns}
          columns={this.tableHeadInit()}
          data={productPageList}
          rowKey="id"
          loading={productPageLoad}
          rowClassName={(record) => record.status == 'DISABLE' ? 'table-gray-row' : ''}
          style={{
            width: '100%',
            maxWidth: '100%',
            border: '1px solid #e2e2e2',
            borderBottom: 'none',
            marginTop: '10px',
          }}
          messageRender={({ data, error }) => {
            if (error != null) {
              return error.msg;
            }
            if (data == null || data.length === 0) {
              return (
                <div className="text-weak">
                  暂无产品{this.props.permissionIds.includes('data.basicData.product.add') ? '，您可以点击左上角' : null}
                  {this.props.permissionIds.includes('data.basicData.product.add') && <span
                    onClick={() => {
                      this.jumpPage();
                    }}
                    style={{ color: '#006EFF' }}
                  >
                    新增产品
                  </span>}
                </div>
              );
            }
          }}
        />
        {productPageList.length > 0 &&
          <Pager
            total={productPageParam.totalElements}
            current={productPageParam.page}
            perPageCount={productPageParam.size}
            onPageChange={value => {
              this.changePage(value, 'page');
            }}
            onPerPageChange={value => {
              this.changePage(value, 'perpage');
            }}
          />}
        <DownLoadAnimate animateIcon='.producttable-container .export' animateShow={this.state.animateShow}></DownLoadAnimate>


        {productModal.show && <ProductModal
          // key={Math.random()}
          productModal={productModal}
          onAwardModalShow={this.productModalShow}
          onChangeAwardData={this.changeAwardData}
        ></ProductModal>
        }

        {
          this.state.THSVisible &&
          <TableHeaderSettingModal
            visible={this.state.THSVisible}
            handleVisible={() => {
              this.props.getEseData({ dictDataType: "PRODUCT" });
              this.setState({ THSVisible: false });
            }}
            eseData={this.props.eseData.map(item => ({ text: item.name, value: item.dictId, id: item.dictId, titleShow: item.titleShow }))}
            handleConfigTableTitle={this.props.handleConfigTableTitle}
            dictDataType='PRODUCT'
          ></TableHeaderSettingModal>
        }
      </div>
    );
  }
}

export default ProductTable;
