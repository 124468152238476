import React, { useMemo, useRef, useEffect, useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { notification } from 'tyb'
import { MultipleRemoteSelect } from 'ucode-components'
import { MultipleSelect } from 'ucode-components'
import { NewSelect } from 'ucode-components'
import { NewRemoteSelect } from 'ucode-components'
import { useStyles } from './hooks'
import { apiBaseConfig } from '@/redux/utils'
import Upload from 'rc-upload'
import url from '@/api/urls'
import $http from '@/utils/http'
import { useGetMainData } from '@/components/hooks'

/**
 * @typedef Props
 * @prop {string|number|string[]} value
 * @prop {(e: string|number|string[]) => void} onChange
 * @prop {'dealer'|'terminal'|'product'} type
 * @prop {boolean=} disabled
 * @prop {string=} placeholder
 * @prop {boolean=} multiple 单选&多选，默认单选
 * @prop {boolean=} canImport 是否可以导入&模板下载，默认false
 * @prop {'id'|'code'=} valueName value字段，默认id
 * @prop {React.CSSProperties} style
 * @prop {(v:{text:string,value:string|number}[]) => {text:string,value:string|number,[key:string]:any}[]} coverList
 */

/**
 * @param {Props} props
 */
function DynamicDataSelect(props) {
  useStyles(
    `
    .DynamicDataSelect-multiple-wrapper {
      display: flex;
      align-items: center
    }
    .DynamicDataSelect-multiple-wrapper .tc-15-dropdown-btn-style .tc-15-dropdown-link {
      display: block;
    }
  `,
    'DynamicDataSelect-style'
  )
  const {
    value,
    onChange,

    type,
    disabled,
    placeholder = '请选择',
    multiple = false,
    canImport = false,
    valueName = 'id',
    style = {},
    coverList,

    eseConfig = {}
  } = props

  const valueNameRef = useRef(valueName)
  useEffect(() => {
    valueNameRef.current = valueName
  }, [valueName])

  const [localList, setLocalList] = useState([])
  const listData = useMemo(() => {
    let list = localList.map(it => ({ text: it.name, value: it[valueName] }))
    if (valueName === 'code') {
      list = list.filter(it => it.value)
    }
    if (coverList && typeof coverList === 'function') {
      list = coverList(list)
    }
    return list
  }, [localList, valueName, coverList])

  const franchiserUploadProps = useMemo(
    () => ({
      ...apiBaseConfig,
      accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,text/csv,.csv',
      action: url.data.upload.uploadMasterQuery('DEALER'),
      multiple: true,
      onSuccess: list => {
        if (list.length) {
          onChange(list.map(item => item[valueNameRef.current]))
        }
      },
      onError: (err, content) => {
        notification.error(content.emsg)
      }
    }),
    []
  )
  const terminalUploadProps = useMemo(
    () => ({
      ...apiBaseConfig,
      accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,text/csv,.csv',
      action: url.data.upload.uploadMasterQuery('TERMINAL'),
      multiple: true,
      onSuccess: list => {
        if (list.length) {
          onChange(list.map(item => item[valueNameRef.current]))
        }
      },
      onError: (err, content) => {
        notification.error(content.emsg)
      }
    }),
    []
  )
  const productUploadProps = useMemo(
    () => ({
      ...apiBaseConfig,
      accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,text/csv,.csv',
      action: url.data.upload.uploadMasterQuery('PRODUCT'),
      multiple: true,
      onSuccess: list => {
        if (list.length) {
          onChange(list.map(item => item[valueNameRef.current]))
        }
      },
      onError: (err, content) => {
        notification.error(content.emsg)
      }
    }),
    []
  )

  const isLazy = useMemo(() => {
    if (type === 'dealer') {
      return !eseConfig.allDealer
    }
    if (type === 'terminal') {
      return !eseConfig.allShop
    }
    if (type === 'product') {
      return !eseConfig.allProduct
    }
    return true
  }, [type, eseConfig])

  const localDealerList = useGetMainData({
    type: 'DEALER',
    keys: value,
    valueName: valueName,
    shouldFetch: () => type === 'dealer'
  })
  const localTerminalList = useGetMainData({
    type: 'SHOP',
    keys: value,
    valueName: valueName,
    shouldFetch: () => type === 'terminal'
  })
  const localProductList = useGetMainData({
    type: 'PRODUCT',
    keys: value,
    valueName: valueName,
    shouldFetch: () => type === 'product'
  })

  const remoteList = useMemo(() => {
    if (type === 'dealer') {
      return localDealerList.map(it => ({ text: it.name, value: it[valueName] }))
    } else if (type === 'terminal') {
      return localTerminalList.map(it => ({ text: it.name, value: it[valueName] }))
    } else if (type === 'product') {
      return localProductList.map(it => ({ text: it.name, value: it[valueName] }))
    }
  }, [type, valueName, localDealerList, localTerminalList, localProductList])
  const list = useMemo(() => {
    let list = remoteList
    if (valueName === 'code') {
      list = remoteList.filter(it => it.value)
    }
    if (coverList && typeof coverList === 'function') {
      list = coverList(list)
    }
    return list
  }, [remoteList, valueName, coverList])

  const onSearchChange = useCallback(
    async word => {
      let url = ''
      if (type === 'dealer') {
        url = '/data/portal/dealers/v1/allots'
      } else if (type === 'terminal') {
        url = '/data/portal/shops/v1/allots'
      } else if (type === 'product') {
        url = '/data/portal/products/v1/allots'
      }
      if (!url) {
        return
      }
      const res = await $http({
        url: url,
        params: {
          status: 'ENABLE',
          word: word,
          size: 10,
          page: true
        }
      })
      setLocalList(res?.content || [])
    },
    [type]
  )

  const importContent = useMemo(() => {
    if (canImport && !disabled) {
      let href = ''
      let prop = null
      if (type === 'dealer') {
        href = `${url.baseCdnUrl}/templates/temp-dealer.csv`
        prop = franchiserUploadProps
      } else if (type === 'terminal') {
        href = `${url.baseCdnUrl}/templates/temp-shop.csv`
        prop = terminalUploadProps
      } else if (type === 'product') {
        href = `${url.baseCdnUrl}/templates/temp-product.csv`
        prop = productUploadProps
      } else {
        return
      }

      return (
        <div style={{ flexShrink: '0', fontSize: '12px' }}>
          <Upload {...prop}>
            <button className="ml-10" style={{ color: '#006eff', backgroundColor: 'transparent' }}>
              导入
            </button>
          </Upload>
          <a href={href} className="ml-10" style={{ color: '#006eff' }}>
            模板下载
          </a>
        </div>
      )
    }
  }, [canImport, disabled, type, franchiserUploadProps])

  if (type === 'dealer' || type === 'terminal' || type === 'product') {
    if (multiple && isLazy) {
      return (
        <div className="DynamicDataSelect-multiple-wrapper">
          <div style={style}>
            <MultipleRemoteSelect
              placeholder={placeholder}
              disabled={disabled}
              style={{ width: '100%' }}
              list={listData}
              remoteList={remoteList}
              value={value}
              onChange={onChange}
              onSearchChange={onSearchChange}
            />
          </div>
          {importContent}
        </div>
      )
    }

    if (multiple && !isLazy) {
      return (
        <div className="DynamicDataSelect-multiple-wrapper">
          <div style={style}>
            <MultipleSelect
              placeholder={placeholder}
              disabled={disabled}
              style={{ width: '100%' }}
              list={list}
              value={value}
              onChange={onChange}
            />
          </div>
          {importContent}
        </div>
      )
    }

    if (!multiple && isLazy) {
      return (
        <NewRemoteSelect
          placeholder={placeholder}
          disabled={disabled}
          style={style}
          list={listData}
          remoteList={remoteList}
          value={value}
          onChange={e => {
            if (e !== value) {
              onChange(e)
            }
          }}
          onSearchChange={onSearchChange}
        />
      )
    }

    if (!multiple && !isLazy) {
      return (
        <NewSelect
          placeholder={placeholder}
          disabled={disabled}
          style={style}
          list={list}
          value={value}
          onChange={e => {
            if (e !== value) {
              onChange(e)
            }
          }}
        />
      )
    }
  }

  return <></>
}

export default connect(state => ({
  eseConfig: state.users.openIds.eseConfig
}))(DynamicDataSelect)
