import React from 'react';
import {Button, FormGroupList, Input, FormGroup, Textarea, Dialog} from 'tyb';
import {reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import { FormGroupField } from 'tyb';
const { getZone } = iceStarkData.store.get('commonAction')?.zone;
const { createBrand, editBrand, loadBrandById } = iceStarkData.store.get('commonAction')?.brand;
import './createBrandForm.scss';


@connect (
  state => {
    return {
      form: state.form,
      ...state.brand,
    };
  },
  {
    createBrand: createBrand.REQUEST,
    editBrand: editBrand.REQUEST,
    loadBrandById: loadBrandById.REQUEST,
  }
)
class brandCreateForm extends React.Component {
  constructor (props) {
    super (props);
    this.state = {
      cityTree: [],
      countyTree: [],
    };
  }
  componentDidMount () {
    const { dataId, loadBrandById, brandDetail= {}, initialize } = this.props;
    if (dataId) {
      this.props.loadBrandById({id: dataId, callback: (res) =>{ initialize(res); }});
    }
  }

  // 品牌名称验证
  brandNameValidator = (value = '') => {
    if (!value || (value && value.trim () === '')) {
      return '请输入品牌名称';
    } else if (value && value.trim ().length > 30) {
      return '长度不超过30个字符';
    } else {
      return '';
    }
  };

  // 统一去前后空格
  trims = obj => {
    for (let key in obj) {
      if (obj[key] == null) {
      }
      else if (obj[key].constructor === String) {
        obj[key] = obj[key].trim();
      } else if (obj[key].constructor === Object) {
        this.trims(obj[key]);
      }
    }
  }
  handleSubmit = data => {
    const {createBrand, editBrand, handleCancel} = this.props;
    this.trims (data);
    if (data.id) {
      editBrand({data, callback: handleCancel});
    } else {
      createBrand({data, callback: handleCancel});
    }
  };

  // 选择省，加载市级树
  provinceCodeChange = (value, level, tree) => {
    const {zoneList, initialize, form: {brandCreateForm = {}}} = this.props;
    initialize ({ ...brandCreateForm.values, countyCode: '', cityCode: level == 2 ? '' : value, });
    this.setState ({ countyTree: [], [tree]: zoneList.filter (v => v.parentCode == value && v.level == level), });
  };

  render () {
    const { handleSubmit, dataId, showbrandCreateForm, handleCancel} = this.props;
    const { zoneList, form: { brandCreateForm = { value: {provinceCode: '', cityCode: '', countyCode: ''}, }, }, } = this.props;
    const {cityTree, countyTree} = this.state;
    if (!brandCreateForm.values) { brandCreateForm.values = { provinceCode: '', cityCode: '', countyCode: '', }; }
    return (
      <Dialog
          title={dataId ? '编辑品牌' : "新增品牌"}
          visible={showbrandCreateForm}
          onCancel={handleCancel}
          hasFooter={false}
          style={{ width: "485px" }}
        >
      <div className="brand-form">
        <FormGroupList>
          <FormGroupField
            name={'name'}
            label="品牌名称"
            control={Input}
            component={FormGroup}
            validate={this.brandNameValidator}
            required
            placeholder="请输入品牌名称"
            text="长度不超过30个字符"
          />
        </FormGroupList>
        
    
        <div className="button-box">
              <Button className='' onClick={ handleSubmit(this.handleSubmit) }>提交</Button>
              <Button className='weak' onClick={handleCancel}>取消</Button>
            </div>
        </div>
        </Dialog>
        
    );
  }
}
export default reduxForm ({
  form: 'brandCreateForm',
}) (brandCreateForm);
