import React, { useContext, useState, useEffect, useRef, useMemo, useCallback } from 'react'
import { ReactReduxContext } from 'react-redux'
const { setNavBar } = iceStarkData.store.get('commonAction')?.ui
import useSWR from 'swr'
import $http from '@/utils/http'

function useStore() {
  const { store } = useContext(ReactReduxContext)
  return store
}

function useDispatch() {
  const { dispatch } = useStore()
  return dispatch
}

function useSelector(selector = () => {}) {
  const { getState, subscribe } = useStore()
  const [storeState, setStoreState] = useState(getState())

  useEffect(
    () =>
      subscribe(() => {
        setStoreState(getState())
      }),
    []
  )

  return selector(storeState)
}

function useNavTitle(title) {
  const dispatch = useDispatch()

  useEffect(() => {
    if (title) {
      dispatch(setNavBar(title))
    }
  }, [])

  return useRef(t => {
    if (t) {
      dispatch(setNavBar(t))
    }
  }).current
}

/**
 * @param {string} styles
 * @param {string=} id
 */
function useStyles(styles, id) {
  useEffect(() => {
    if (id && document.getElementById(id)) {
      return
    }

    const head = document.head || document.getElementsByTagName('head')[0]
    const style = document.createElement('style')
    if (id) {
      style.setAttribute('id', id)
    }
    style.setAttribute('type', 'text/css')
    style.innerHTML = styles
    head.appendChild(style)

    return () => {
      try {
        if (id) {
          head.removeChild(document.getElementById(id))
        } else {
          head.removeChild(style)
        }
      } catch (err) {}
    }
  }, [])
}

/**
 * @param {{ shouldFetch?: () => boolean }}
 * @return {{ id: number, code: string, name: string }[]}
 */
function useShops({ shouldFetch = () => true }) {
  const empty = useRef([])
  const { data } = useSWR(
    shouldFetch() ? 'shops' : null,
    () => $http({ url: '/data/portal/shops/v1/allots?isPage=false&status=ENABLE' }),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  )

  if (!data) {
    return empty.current
  } else {
    return data.content || empty.current
  }
}

/**
 * @param {{ shouldFetch?: () => boolean }}
 * @return {{ id: number, code: string, name: string }[]}
 */
function useDealers({ shouldFetch = () => true }) {
  const empty = useRef([])
  const { data } = useSWR(
    shouldFetch() ? 'dealers' : null,
    () => $http({ url: '/data/portal/dealers/v1/allots?isPage=false&status=ENABLE' }),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  )

  if (!data) {
    return empty.current
  } else {
    return data.content || empty.current
  }
}

/**
 * @param {{ shouldFetch?: () => boolean }}
 * @return {{ id: number, code: string, name: string }[]}
 */
function useProducts({ shouldFetch = () => true }) {
  const empty = useRef([])
  const { data } = useSWR(
    shouldFetch() ? 'products' : null,
    () => $http({ url: '/data/portal/products/v1/allots?isPage=false&status=ENABLE' }),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  )

  if (!data) {
    return empty.current
  } else {
    return data.content || empty.current
  }
}

function laggy(useSWRNext) {
  return (key, fetcher, config) => {
    // 使用 ref 来存储之前返回的数据。
    const laggyDataRef = useRef()

    // 实际的 SWR hook。
    const swr = useSWRNext(key, fetcher, config)

    useEffect(() => {
      // 如果数据不是 undefined，更新 ref。
      if (swr.data !== undefined) {
        laggyDataRef.current = swr.data
      }
    }, [swr.data])

    // 暴露一个方法来清除延迟的数据（如果有）。
    const resetLaggy = useCallback(() => {
      laggyDataRef.current = undefined
    }, [])

    // 如果当前数据是 undefined，则回退到之前的数据。
    const dataOrLaggyData = swr.data === undefined ? laggyDataRef.current : swr.data

    // 是否显示之前的数据？
    const isLagging = swr.data === undefined && laggyDataRef.current !== undefined

    // 同时将 `isLagging` 字段添加到 SWR 中。
    return Object.assign({}, swr, {
      data: dataOrLaggyData,
      isLagging,
      resetLaggy
    })
  }
}

/**
 * @param {{ keys: any[], valueName: 'id'|'code', shouldFetch: () => boolean, type: 'DEALER'|'SHOP'|'PRODUCT' }}
 * @return {{ id: number, code: string, name: string }[]}
 */
function useGetMainData({ keys, valueName = 'id', shouldFetch = () => true, type = 'DEALER' }) {
  const eseConfig = useSelector(state => state.users?.openIds?.eseConfig || {})
  const isLazy = useMemo(() => {
    if (type === 'DEALER') {
      return !eseConfig.allDealer
    }
    if (type === 'SHOP') {
      return !eseConfig.allShop
    }
    if (type === 'PRODUCT') {
      return !eseConfig.allProduct
    }
    return true
  }, [type, eseConfig])

  const keysStr = useMemo(() => {
    try {
      if (typeof keys === 'string' || typeof keys === 'number') {
        return `${keys}`
      }
      return [...keys].sort().join()
    } catch (err) {
      return ''
    }
  }, [keys])

  const fetcher = useCallback(() => {
    if (valueName === 'id') {
      return $http({
        url: '/data/portal/master-query/v1/ids',
        params: {
          ids: keysStr,
          dataType: type
        }
      })
    } else {
      return $http({
        url: '/data/portal/master-query/v1/codes',
        params: {
          codes: keysStr,
          dataType: type
        }
      })
    }
  }, [valueName, keysStr, type])

  const empty = useRef([])
  const { data } = useSWR(shouldFetch() && keysStr && isLazy ? `${type} ${keysStr}` : null, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    use: [laggy]
  })

  const allDealers = useDealers({ shouldFetch: () => shouldFetch() && type === 'DEALER' && !isLazy })
  const allShops = useShops({ shouldFetch: () => shouldFetch() && type === 'SHOP' && !isLazy })
  const allProducts = useProducts({ shouldFetch: () => shouldFetch() && type === 'PRODUCT' && !isLazy })

  if (isLazy && data) {
    return data
  } else if (isLazy) {
    return empty.current
  } else {
    if (type === 'DEALER') return allDealers
    if (type === 'SHOP') return allShops
    if (type === 'PRODUCT') return allProducts
  }
  return empty.current
}

export {
  useStore,
  useDispatch,
  useSelector,
  useNavTitle,
  useStyles,
  useShops,
  useDealers,
  useProducts,
  useGetMainData
}
