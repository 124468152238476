import React from 'react';
import { reduxForm, FieldArray } from 'redux-form'
import queryString from 'query-string';
import { Dialog, Tip, FormGroupList, Radio, Input, DatePicker, DateTimePicker, Button, RadioButtonGroup, DropdownList, Textarea } from 'tyb';
import { FormGroupField } from 'tyb'
import { formatDate } from 'ucode-utils';
import { NewSelect } from 'ucode-components';
import { connect } from 'react-redux';
import { MultipleSelect } from 'ucode-components';
const { loadEnterprisesList, createEnterprise, editEnterprise, enterpriseTimeGet, createEnterpriseTime } = iceStarkData.store.get('commonAction')?.enterprises;
const { authorization, refresh, sendInvitationCode } = iceStarkData.store.get('commonAction')?.users;

const { editenterpriseinfo, enterpriseinfo, loadenterpriseinfolist, editenterpriseinfoWarning } = iceStarkData.store.get('commonAction')?.enterpriseinfo;
const { loaddomainConfigsList, addDomainConfigs, editDomainConfigs } = iceStarkData.store.get('commonAction')?.domainConfigs;




import { withRouter } from "react-router-dom";
const { notification } = iceStarkData.store.get('commonAction')?.ui;

import { trims } from 'ucode-utils';

import enums from '@/assets/enum.json'
// import TextArea from 'antd/lib/input/TextArea';

const setting = (props) => {
    return (
        <p style={{ fontSize: '12px', marginBottom: 0 }}>{props.showValue}</p>
    )
}

@withRouter
@connect(
    (state) => ({
        roleSelections: state.role.roleSelections,
        enterprisesList: state.enterprises.enterprisesList,

        codedbList: state.codedb.codedbListSelect,


        enterpriseinfoinfo: state.enterpriseinfo.enterpriseinfoinfo,
        ViewDialog: state.form.ViewDialog

    }),
    {

        loadEnterprisesList: loadEnterprisesList.REQUEST,
        createEnterprise: createEnterprise.REQUEST,
        editEnterprise: editEnterprise.REQUEST,
        editenterpriseinfoWarning: editenterpriseinfoWarning.REQUEST,
        authorization: authorization.REQUEST,
        refresh: refresh.REQUEST,
        sendInvitationCode: sendInvitationCode.REQUEST,


        editenterpriseinfo: editenterpriseinfo.REQUEST,
        loaddomainConfigsList: loaddomainConfigsList.REQUEST,
        loadenterpriseinfolist: loadenterpriseinfolist.REQUEST,
        enterpriseinfo: enterpriseinfo.REQUEST,
        addDomainConfigs: addDomainConfigs.REQUEST,
        editDomainConfigs: editDomainConfigs.REQUEST,

        enterpriseTimeGet: enterpriseTimeGet.REQUEST,
        createEnterpriseTime: createEnterpriseTime.REQUEST,



        notification: notification,
    }
)
class ViewDialog0 extends React.Component {



    constructor(props) {
        super(props);
        this.state = {
            checked: 1,
            nowDate: formatDate(new Date()),
            list: [
                {
                    title: '1万',
                    text: '1万',
                    value: '10000'
                },
                {
                    title: '10万',
                    text: '10万',
                    value: '100000'
                },
                {
                    title: '100万',
                    text: '100万',
                    value: '1000000',

                },
                {
                    title: '1000万',
                    text: '1000万',
                    value: '10000000',

                }
            ]
        };
    }
    validateNumber = (value) => {
        let re = /^[1-9][0-9]*$/
        let value2 = '';
        if (value || value == '0') {
            value2 = value + "";
        }
        if (value2 === '' || value2.trim().length == 0) {
            // return '不能为空';
        }
        else if (value2.trim() === '0') {
            // return '不能为0';
        }
        else if (!re.test(value2.trim())) {
            return '请输入数字值';
        }
        else if (value2.trim() > 999999999) {
            return '最大999999999';
        }
    }


    validateName = (value) => {
        let value2 = '';
        if (value || value == '0') {
            value2 = value + "";
        }
        if (value2 === '' || value2.trim().length == 0) {
            return '企业名称不能为空';
        }
        else if (value2.trim().length > 30) {
            return '企业名称最多30个字符';
        }
    }
    validateContacts = (value) => {
        let value2 = '';
        if (value || value == '0') {
            value2 = value + "";
        }
        if (value2 === '' || value2.trim().length == 0) {
            return '联系人不能为空';
        }
        else if (value2.trim().length > 30) {
            return '联系人最多30个字符';
        }
    }
    validatePhone = (value) => {
        let value2 = '';
        if (value || value == '0') {
            value2 = value + "";
        }
        if (value2 === '' || value2.trim().length == 0) {
            return '电话不能为空';
        }
        else if (value2.trim().length > 30) {
            return '电话最多30个字符';
        }
    }
    validateEmail = (value) => {
        let value2 = '';
        if (value || value == '0') {
            value2 = value + "";
        }
        if (value2 === '' || value2.trim().length == 0) {
            return '邮箱不能为空';
        }
        else if (value2.trim().length > 100) {
            return '邮箱最多100个字符';
        }
    }

    validateType = (value) => {
        let value2 = '';
        if (value || value == '0') {
            value2 = value + "";
        }
        if (value2 === '' || value2.trim().length == 0) {
            return '使用平台不能为空';
        }
    }

    validateusername = (value) => {
        let value2 = '';
        if (value || value == '0') {
            value2 = value + "";
        }
        if (value2 === '' || value2.trim().length == 0) {
            return '用户名不能为空';
        }
        else if (value2.trim().length > 50) {
            return '用户名最多50个字符';
        }
    }

    validatepassword = (value) => {
        let value2 = '';
        if (value || value == '0') {
            value2 = value + "";
        }
        if (value2 === '' || value2.trim().length == 0) {
            return '密码不能为空';
        }
        else if (!/^(?!^.*[\u4E00-\u9FA5].*$)(?=.*[a-zA-Z].*)(?=.*[0-9].*)(?=.*((?=[\x21-\x7e]+)[^A-Za-z0-9]).*)\S{8,30}$/.test(value2.trim())) {
            return '密码为包含数字、大小写字母、特殊字符任意三种组合，长度大于8，小于30的字符';
        }
        else if (value2.trim().length > 50) {
            return '密码最多50个字符';
        }
    }

    validateCount = (value) => {
        if (this.state.checked == 1) {
            let re = /^[1-9][0-9]*$/
            let value2 = '';
            if (value || value == '0') {
                value2 = value + "";
            }
            if (value2 === '' || value2.trim().length == 0) {
                return '不能为空';
            }
            else if (value2.trim() === '0') {
                return '不能为0';
            }
            else if (!re.test(value2.trim())) {
                return '请输入数字值';
            }
            else if (value2.trim() > 999999999) {
                return '最大999999999';
            }

        }
    }

    validateCount2 = (value) => {
        if (this.state.checked == 2) {
            let re = /^[1-9][0-9]*$/
            let value2 = '';
            if (value || value == '0') {
                value2 = value + "";
            }
            if (value2 === '' || value2.trim().length == 0) {
                return '不能为空';
            }
            else if (value2.trim() === '0') {
                return '不能为0';
            }
            else if (!re.test(value2.trim())) {
                return '请输入数字值';
            }
            else if (value2.trim() > 999999999) {
                return '最大999999999';
            }
        }
    }

    validateRoleIds = (value) => {
        if (value.length == 0) {
            return '请选择角色'
        }
    }


    validateName2 = (value) => {
        let value2 = '';
        if (value || value == '0') {
            value2 = value + "";
        }
        if (value2 === '' || value2.trim().length == 0) {
            return '域名名称不能为空';
        }
        else if (value2.trim().length > 30) {
            return '域名名称最多30个字符';
        }
    }
    validateRegex = (value) => {
        let value2 = '';
        if (value || value == '0') {
            value2 = value + "";
        }
        if (value2 === '' || value2.trim().length == 0) {
            return '截取规则不能为空';
        }
        else if (value2.trim().length > 30) {
            return '截取规则最多30个字符';
        }
    }
    validateDomain = (value) => {
        let { ViewDialog } = this.props;
        let value2 = '';
        let a = ViewDialog ? ViewDialog.values ? (ViewDialog.values.codeSource == 'PLATFORM_STANDARD'|| ViewDialog.values.codeSource == 'WRITE_OFF_CODE' || ViewDialog.values.codeSource == 'PLATFORM_SHORT_CODE') && (ViewDialog.values.protocol == 'HTTP://' || ViewDialog.values.protocol == 'HTTPS://') ? true : false : false : false;
        if (value || value == '0') {
            value2 = value + "";
        }
        if (value2 === '' || value2.trim().length == 0) {
            return '域名不能为空';
        }
        else if (value2.trim().length > 30) {
            return '域名最多30个字符';
        }
        else if (value2.trim().indexOf(' ') >= 0) {
            return '域名不能包含空格';
        }
        else if (!/^\s*[0-9a-zA-Z.]+\s*$/.test(value2)) {
            return '不能输入特殊字符';
        }
        else if (a) {
            if (!/^\s*[0-9A-Z.]+\s*$/.test(value2)) {
                return '请输入大写';
            }
        }
    }
    validateAppId = (value) => {
        let value2 = '';
        if (value || value == '0') {
            value2 = value + "";
        }
        if (value2 === '' || value2.trim().length == 0) {
            return 'APPID不能为空';
        }
        else if (value2.trim().length > 30) {
            return 'APPID最多30个字符';
        }
    }
    validateSubDomain = (value) => {
        let { ViewDialog } = this.props;
        let a = ViewDialog ? ViewDialog.values ? ViewDialog.values.codeSource == 'PLATFORM_STANDARD' ? true : false : false : false;
        let b = ViewDialog ? ViewDialog.values ? (ViewDialog.values.codeSource == 'PLATFORM_STANDARD' || ViewDialog.values.codeSource == 'PLATFORM_SHORT_CODE') && (ViewDialog.values.protocol == 'HTTP://' || ViewDialog.values.protocol == 'HTTPS://') ? true : false : false : false;
        let c = ViewDialog ? ViewDialog.values ? (ViewDialog.values.codeSource == 'PLATFORM_STANDARD' || ViewDialog.values.codeSource == 'PLATFORM_SHORT_CODE') ? true : false : false : false;

        let value2 = '';
        if (value || value == '0') {
            value2 = value + "";
        }
        if (value2 === '' || value2.trim().length == 0) {
            if (!a) {

            } else {
                return '子域名不能为空';
            }

        }
        else if (c && value2.trim().length > 4) {
            return '子域名最多4个字符';
        }
        else if (value2.trim().indexOf(' ') >= 0) {
            return '子域名不能包含空格';
        }
        else if (!/^\s*[0-9a-zA-Z]+\s*$/.test(value2)) {
            return '不能输入特殊字符';
        }
        else if (b) {
            if (!/^\s*[0-9A-Z]+\s*$/.test(value2)) {
                return '请输入大写';
            }
        }

    }

    validateQueryRules = (value) => {
        let value2 = '';
        if (value || value == '0') {
            value2 = value + "";
        }
        if (value2 === '' || value2.trim().length == 0) {
            return '码查询规则不能为空';
        }
        else if (value2.trim().length > 30) {
            return '码查询规则最多30个字符';
        }
    }
    validatebankName = (value) => {
        if (!value) {
            return '请选择所属码库'
        }
    }



    validateRemark = (value) => {
        let value2 = '';
        if (value || value == '0') {
            value2 = value + "";
        }
        if (value2 === '' || value2.trim().length == 0) {
            return '变更原因不能为空';
        }
        else if (value2.trim().length > 300) {
            return '变更原因最多300个字符';
        }
    }

    componentDidMount() {
        // this.setState({ viewObject: JSON.parse(JSON.stringify(this.props.viewObject)) })

        // console.log(this.props.viewObject)
        if (this.props.visible == 2) {
            let olddata = this.props.viewObject
            this.props.initialize({ ...olddata, password: olddata.password ? '**********' : '' })
        }
        else if (this.props.visible == 3) {
            let olddata = this.props.viewObject
            this.props.initialize({ roleIds: olddata.roleIds ? olddata.roleIds : [] })
        }
        else if (this.props.visible == 6) {

            this.props.initialize({ codeSource: 'PLATFORM_STANDARD', protocol: "HTTP://", })
        }
        else if (this.props.visible == 7) {
            let olddata = this.props.viewObject
            this.props.initialize({ ...olddata, codeSource: olddata.codeSourceType })
        }
        else if (this.props.visible == 8) {
            let olddata = this.props.viewObject
            // codeSource: olddata.codeSourceType
            this.props.initialize({ ...olddata, })
        }
        else if (this.props.visible == 9) {
            this.props.initialize({ expirationTime: this.state.nowDate })
        }
        // else if (this.props.visible == 2 || this.props.visible == 4) {
        //     this.props.initialize({ executionTime: this.state.nowDate })
        // }
        // else if (this.props.visible == 3) {
        //     this.props.initialize({ productionTime: this.state.nowDate })
        // }


        // else if (this.props.visible == 5) {
        //     this.props.initialize({ productionTime: this.state.nowDate, seqnums: [{}] })
        //     this.state.formdeText = [''];
        // }
        // else if (this.props.visible == 6) {
        //     let olddata = this.props.viewObject.dimension ? this.props.viewObject.dimension.register ? JSON.parse(JSON.stringify(this.props.viewObject.dimension.register)) : {} : {};
        //     if (olddata.productionTime) {
        //         olddata.productionTime = formatDate((new Date(parseInt(olddata.productionTime))))
        //     }
        //     this.props.initialize({ productionTime: this.state.nowDate, ...olddata })
        // }
        // else if (this.props.visible == 7 || this.props.visible == 9) {
        //     this.props.initialize({ executionTime: this.state.nowDate })
        // }
        // else if (this.props.visible == 8) {
        //     this.props.initialize({ productionTime: this.state.nowDate })
        // }



    }
    modalOk = (values) => {
        var encrypt = new JSEncrypt();
        encrypt.setPublicKey(`MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCQqJ4SyujGCKwQvNzsKVMcoSQnoE60zKSPoUen7c+FRqJqineEFNhk7Hc8qWfZDbzMtIAMeg78+SgiOJ1es+KOIUOncaN0dae34QP5AdmORAm1AUXG8osmCe54SU0cnnIwS6L6RE6OIiFZzf//Eo3blf1x3sBLc6WZ/KDSqRLKCwIDAQAB`);

        if (this.props.visible == 1) {
            let newdata = { ...values };

            newdata.password = encrypt.encrypt(newdata.password);
            
            this.props.createEnterprise(
                {
                    data: trims(newdata),
                    callback: () => {
                        this.props.handleCancel();
                        this.props.loadEnterprisesList(this.props.params)
                    }
                }
            )
        }
        else if (this.props.visible == 2) {
            let newdata = { ...values };

            newdata.password = encrypt.encrypt(newdata.password);
            
            
            if (newdata.password == '**********') {
                delete newdata.password
            }
           
            this.props.editEnterprise(
                {
                    data: trims(newdata),
                    callback: () => {
                        this.props.handleCancel();
                        this.props.loadEnterprisesList(this.props.params)
                    }
                }
            )
        }
        else if (this.props.visible == 3) {
            let newdata = values;
            this.props.authorization(
                {
                    data: { ...newdata, id: this.props.viewObject.masterId },
                    callback: () => {
                        this.props.handleCancel();
                        this.props.loadEnterprisesList(this.props.params)
                    }
                }
            )
        }
        else if (this.props.visible == 5) {
            let newdata = values;
            this.props.editenterpriseinfo(
                {
                    data: {
                        adjustmentType: this.state.checked == 1 ? 'ADD' : 'CUTDOWN',
                        eseId: this.props.viewObject.id,
                        count: this.state.checked == 1 ? values.count1 : values.count2,
                        version: this.props.enterpriseinfoinfo ? this.props.enterpriseinfoinfo.version : 1,
                    },
                    callback: () => {

                        this.props.handleCancel();
                        this.props.loadenterpriseinfolist(this.props.params)

                        const parsed = queryString.parse(this.props.location.search);
                        const { id } = parsed;
                        this.props.enterpriseinfo(id)
                    }
                }
            )
        }
        else if (this.props.visible == 6) {
            let newdata = {
                eseId: this.props.viewObject.id,
                name: values.name,
                domain: values.domain,
                subDomain: values.subDomain,
                codeSourceType: values.codeSource,
                protocol: values.codeSource == 'WECHART_CODE' ? '' : values.protocol,
                // values.codeSource == 'PLATFORM_STANDARD' ? 'HTTP://' :
                queryRules: values.queryRules,
                bankName: values.bankName,
            }

            newdata.codeSourceType == 'WECHART_CODE' ? newdata.regex = '' : '';
            newdata.codeSourceType == 'CODE_IMPORT' ? newdata.regex = values.regex : '';
            newdata.codeSourceType == 'PLATFORM_SHORT_CODE' || newdata.codeSourceType == 'PLATFORM_STANDARD' || newdata.codeSourceType == 'WRITE_OFF_CODE' ? newdata.regex = newdata.protocol + newdata.domain + '/' + newdata.subDomain + '/' : '';

            this.props.addDomainConfigs(
                {
                    data: trims(newdata),
                    callback: () => {

                        this.props.handleCancel();
                        this.props.loaddomainConfigsList(this.props.params)
                    }
                }
            )
        }
        else if (this.props.visible == 7) {
            let newdata = {
                id: values.id,
                version: values.version,
                name: values.name,
                // domain: values.domain,
                // subDomain: values.subDomain,
                codeSourceType: values.codeSource,
                regex: values.regex,
                // protocol: values.codeSource == 'PLATFORM_STANDARD' ? 'HTTP://' : values.codeSource == 'WECHART_CODE' ? '' : values.protocol,
                queryRules: values.queryRules,
                bankName: values.bankName,
            }
            this.props.editDomainConfigs(
                {
                    data: trims(newdata),
                    callback: () => {

                        this.props.handleCancel();
                        this.props.loaddomainConfigsList(this.props.params)
                    }
                }
            )
        }
        else if (this.props.visible == 8) {
            console.log(values.id)
            let newdata = {
                eseId: values.id,
                // version: values.version,
                warningValue: values.warningValue,
            }
            this.props.editenterpriseinfoWarning(
                {
                    data: trims(newdata),
                    callback: () => {
                        this.props.handleCancel();
                        const parsed = queryString.parse(this.props.location.search);
                        const { id } = parsed;
                        this.props.enterpriseinfo(id)
                        // this.props.loadenterpriseinfolist(this.props.params)
                    }
                }
            )
        }
        else if (this.props.visible == 9) {
            const parsed = queryString.parse(this.props.location.search);
            const { id } = parsed;
            let newdata = {
                remark: values.remark,
                expirationTime: new Date(values.expirationTime).getTime(),
                eseId: id,
            }

            // console.log(newdata)
            this.props.createEnterpriseTime(
                {
                    data: trims(newdata),
                    callback: () => {
                        this.props.handleCancel();

                        this.props.enterpriseTimeGet(this.props.params)
                        // this.props.loadenterpriseinfolist(this.props.params)
                    }
                }
            )
        }
    }

    refreash = () => {
        this.props.refresh(
            {
                data: { id: this.props.viewObject.masterId },
                callback: () => {
                    // this.props.handleCancel();
                    this.props.loadEnterprisesList(this.props.params)
                }
            }
        )
    }
    sendInvitationCode = () => {

        this.props.sendInvitationCode(
            {
                data: { ids: [this.props.viewObject.masterId] },
                callback: () => {
                    // this.props.handleCancel();
                    // this.props.loadEnterprisesList(this.props.params)
                }
            }
        )
    }

    render() {
        const { visible, handleCancel, viewType, selector, factorySelector, handleSubmit, ViewDialog, codedbList } = this.props;
        return (
            <div>
                {(visible == 1 || visible == 2 || visible == 3) &&
                    <Dialog className='enterprisesModal'
                        title={visible == 1 ? '新增企业' : visible == 2 ? '编辑企业' : visible == 3 ? '选择权限' : ''}
                        visible={visible}
                        onCancel={handleCancel}
                        onOk={handleSubmit(this.modalOk)}
                        style={visible == 3 ? { width: 330, minWidth: 330 } : { width: '480px' }}
                    >
                        {(visible == 1 || visible == 2) && <div>
                            <FormGroupList >
                                <FormGroupField
                                    name="name"
                                    label='企业名称'
                                    placeholder="企业名称"
                                    validate={this.validateName}
                                    component={Input}
                                />

                                <FormGroupField
                                    name="contacts"
                                    label='联系人'
                                    placeholder="联系人"
                                    validate={this.validateContacts}
                                    component={Input}
                                />

                                <FormGroupField
                                    name="phone"
                                    label='电话'
                                    placeholder="电话"
                                    validate={this.validatePhone}
                                    component={Input}

                                />
                                <FormGroupField
                                    name="email"
                                    label='邮箱'
                                    placeholder="邮箱"
                                    validate={this.validateEmail}
                                    component={Input}
                                />
                                <FormGroupField
                                    name="type"
                                    label='使用平台'
                                    placeholder="使用平台"
                                    validate={this.validateType}
                                    // 迎驾贡注释
                                    // list={[{ text: '腾讯平台', value: 'TENCENT' }, { text: '独立平台', value: 'TY' }]}
                                    list={[{ text: '独立平台', value: 'TY' }]}
                                    component={NewSelect}
                                    style={{ width: 330 }}
                                />
                                {ViewDialog ? ViewDialog.values ? ViewDialog.values.type == 'TY' ?
                                    <React.Fragment>
                                        <FormGroupField
                                            name="emailAccount"
                                            label='用户名'
                                            placeholder="用户名"
                                            validate={this.validateusername}
                                            component={Input}
                                        />
                                        <FormGroupField
                                            name="password"
                                            label='密码'
                                            placeholder="密码"
                                            validate={this.validatepassword}
                                            component={Input}
                                            type='password'
                                        />
                                    </React.Fragment>
                                    : '' : '' : ''}


                            </FormGroupList >

                        </div>}

                        {(visible == 3) && <div>
                            <FormGroupList >
                                <FormGroupField
                                    name="roleIds"
                                    label='选择角色'
                                    placeholder="选择角色"
                                    validate={this.validateRoleIds}
                                    component={MultipleSelect}
                                    list={this.props.roleSelections}
                                    style={{ width: 180 }}
                                />
                            </FormGroupList>
                        </div>
                        }
                    </Dialog>}
                {(visible == 4) &&
                    <Dialog
                        title={visible == 4 ? '邀请码' : ''}
                        visible={visible}
                        onCancel={handleCancel}
                        onOk={handleSubmit(this.modalOk)}
                        style={visible == 4 ? { width: 330, minWidth: 330 } : { width: '480px' }}
                        footerRender={() =>
                            <div className="tc-15-rich-dialog-ft-btn-wrap">
                                <Button onClick={this.refreash}>刷新</Button>
                                <Button className="weak-button" onClick={this.sendInvitationCode}>发送给人员</Button>
                            </div>}
                    >
                        <div>
                            <FormGroupList >
                                <FormGroupField
                                    name="name"
                                    label='邀请码'
                                    placeholder="邀请码"
                                    // validate={this.validateName}
                                    component={setting}

                                    showValue={this.props.enterprisesList.content.filter(key => key.id == this.props.viewObject.id)[0].invitationCode}
                                />
                            </FormGroupList>
                        </div>
                    </Dialog>}
                {(visible == 5) &&
                    <Dialog className='enterprisesModal'
                        title={visible == 5 ? '码量分配' : ''}
                        visible={visible}
                        onCancel={handleCancel}
                        onOk={handleSubmit(this.modalOk)}
                        style={visible == 5 ? { width: '480px' } : { width: '480px' }}
                    >
                        <div style={{ marginBottom: 20 }}>

                            <div style={{ display: 'inline-block' }} className='nolabel'>

                                <FormGroupList >
                                    <FormGroupField
                                        // label='奖品名称'
                                        name="delayMinutes222"
                                        component={Radio}
                                        placeholder=""
                                        value={1}
                                        checked={this.state.checked == 1 ? true : false}
                                        onChange={_ => {
                                            this.setState({
                                                checked: 1
                                            })
                                        }}
                                    />
                                </FormGroupList>
                            </div>
                            <span>追加</span>
                            <div className='nolabel' style={{ marginTop: -5, verticalAlign: 'top' }} >
                                <FormGroupList >
                                    <FormGroupField
                                        // label='奖品名称'
                                        name="count1"
                                        component={Input}
                                        type="text"
                                        placeholder=""
                                        style={{ width: 330, display: 'inline-block' }}
                                        validate={this.validateCount}
                                        disabled={this.state.checked != 1 ? true : false}
                                    />
                                </FormGroupList>
                            </div>
                            <div className='nolabelnolabel' style={{ marginLeft: 50, marginTop: 10, verticalAlign: 'top' }} >
                                <FormGroupList >
                                    <FormGroupField
                                        // label='奖品名称'
                                        list={this.state.list}
                                        name="count1"
                                        validate={this.validateCount}
                                        component={RadioButtonGroup}
                                        disabled={this.state.checked != 1 ? true : false}
                                    />
                                </FormGroupList>
                            </div>

                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <div style={{ display: 'inline-block' }} className='nolabel'>

                                <FormGroupList >
                                    <FormGroupField
                                        // label='奖品名称'
                                        name="delayMinutes222"
                                        component={Radio}
                                        placeholder=""
                                        value={2}
                                        checked={this.state.checked == 2 ? true : false}
                                        onChange={_ => {
                                            this.setState({
                                                checked: 2
                                            })
                                        }}
                                    />
                                </FormGroupList>
                            </div>

                            <span>减少</span>
                            <div className='nolabel' style={{ marginTop: -5, verticalAlign: 'top' }} >
                                <FormGroupList >
                                    <FormGroupField
                                        // label='奖品名称'
                                        name="count2"
                                        component={Input}
                                        type="text"
                                        placeholder=""
                                        style={{ width: 330, display: 'inline-block' }}
                                        validate={this.validateCount2}
                                        disabled={this.state.checked != 2 ? true : false}
                                    />
                                </FormGroupList>
                            </div>
                            <div className='nolabelnolabel' style={{ marginLeft: 50, marginTop: 10, verticalAlign: 'top' }} >
                                <FormGroupList >
                                    <FormGroupField
                                        // label='奖品名称'
                                        list={this.state.list}
                                        name="count2"
                                        validate={this.validateCount2}
                                        component={RadioButtonGroup}
                                        disabled={this.state.checked != 2 ? true : false}
                                    />
                                </FormGroupList>
                            </div>
                        </div>
                    </Dialog>}
                {(visible == 6 || visible == 7) &&
                    <Dialog
                        title={visible == 6 || visible == 7 ? '域名设置' : ''}
                        visible={visible}
                        onCancel={handleCancel}
                        onOk={handleSubmit(this.modalOk)}
                        style={visible == 6 || visible == 7 ? { width: '520px' } : { width: '520px' }}
                    >
                        <div>

                            <FormGroupList >
                                <FormGroupField
                                    name="name"
                                    label='域名名称'
                                    placeholder="域名名称"
                                    validate={this.validateName2}
                                    required={ViewDialog ? ViewDialog.values ? ViewDialog.values.codeSource == 'PLATFORM_SHORT_CODE' || ViewDialog.values.codeSource == 'CODE_IMPORT' ? true : false : false : false}
                                    component={Input}

                                />

                                <FormGroupField
                                    name="codeSource"
                                    label='域名类型'
                                    placeholder="域名类型"
                                    validate={this.validateContacts}
                                    required={ViewDialog ? ViewDialog.values ? ViewDialog.values.codeSource == 'PLATFORM_SHORT_CODE' || ViewDialog.values.codeSource == 'CODE_IMPORT' ? true : false : false : false}
                                    style={{ width: 330 }}
                                    component={NewSelect}
                                    // 迎驾贡注释 只保留平台标准
                                    // list={Object.keys(enums.codeSourceType).map(item => { return { value: item, text: enums.codeSourceType[item].name } })}
                                    list={Object.keys(enums.codeSourceType).filter(v => v == 'PLATFORM_STANDARD').map(item => { return { value: item, text: enums.codeSourceType[item].name } })}
                                    // list={[{value: 'PLATFORM_STANDARD', text: '平台标准'}]}
                                    disabled={visible == 7}
                                    onChange={() => {

                                        this.props.change('domain', '')
                                        this.props.change('subDomain', '')
                                    }}

                                />
                                {ViewDialog ? ViewDialog.values ?


                                    // ViewDialog.values.codeSource == 'PLATFORM_STANDARD' ?
                                    //     <FormGroupField
                                    //         name="domain"
                                    //         label='域名'
                                    //         placeholder="域名"
                                    //         validate={this.validateDomain}
                                    //         component={Input}
                                    //         addonBefore={<span>HTTP://</span>}
                                    //         style={{ width: 266 }}
                                    //         disabled={visible == 7}
                                    //     />
                                    //     :
                                    ViewDialog.values.codeSource == 'PLATFORM_STANDARD' || ViewDialog.values.codeSource == 'WRITE_OFF_CODE' || ViewDialog.values.codeSource == 'PLATFORM_SHORT_CODE' || ViewDialog.values.codeSource == 'CODE_IMPORT' ?
                                        <React.Fragment>
                                            <FormGroupField
                                                name="protocol"
                                                label='域名'
                                                onChange={() => {

                                                    this.props.change('domain', '')
                                                    this.props.change('subDomain', '')
                                                }}
                                                // placeholder="域名"
                                                // validate={this.validatePhone}
                                                required={ViewDialog ? ViewDialog.values ? ViewDialog.values.codeSource == 'PLATFORM_STANDARD' || ViewDialog.values.codeSource == 'WRITE_OFF_CODE' || ViewDialog.values.codeSource == 'PLATFORM_SHORT_CODE' || ViewDialog.values.codeSource == 'CODE_IMPORT' ? true : false : false : false}
                                                disabled={visible == 7}
                                                component={NewSelect}
                                                // style={{ width: 56 }}
                                                style={{ width: 330 }}
                                                list={
                                                    ViewDialog ? ViewDialog.values ? ViewDialog.values.codeSource == 'PLATFORM_STANDARD' || ViewDialog.values.codeSource == 'PLATFORM_SHORT_CODE' ?
                                                        [
                                                            {
                                                                "text": "HTTP://",
                                                                "value": "HTTP://",
                                                            },
                                                            {
                                                                "text": "HTTPS://",
                                                                "value": "HTTPS://",
                                                            },

                                                        ] :
                                                        [
                                                            {
                                                                "text": "HTTP://",
                                                                "value": "HTTP://",
                                                            },
                                                            {
                                                                "text": "HTTPS://",
                                                                "value": "HTTPS://",
                                                            },
                                                            {
                                                                "text": "http://",
                                                                "value": "http://",
                                                            },
                                                            {
                                                                "text": "https://",
                                                                "value": "https://",
                                                            },

                                                        ] : [] : []}

                                            />
                                            <FormGroupField
                                                name="domain"
                                                // label='域名'
                                                placeholder="域名"
                                                validate={this.validateDomain}
                                                component={Input}
                                                disabled={visible == 7}
                                            // style={{ width: 274 }}
                                            />
                                        </React.Fragment>
                                        :
                                        ViewDialog.values.codeSource == 'WECHART_CODE' ?
                                            <FormGroupField
                                                name="domain"
                                                label='发码公众号APPID'
                                                placeholder="发码公众号APPID"
                                                validate={this.validateAppId}
                                                component={Input}
                                                disabled={visible == 7}
                                            />
                                            : '' : '' : ''}

                                {ViewDialog ? ViewDialog.values ? ViewDialog.values.codeSource != 'WECHART_CODE' &&
                                    <FormGroupField
                                        name="subDomain"
                                        label='子域名'
                                        placeholder="子域名"
                                        validate={this.validateSubDomain}
                                        component={Input}
                                        disabled={visible == 7}
                                    /> : '' : ''}

                                {ViewDialog ? ViewDialog.values ? ViewDialog.values.codeSource != 'PLATFORM_STANDARD' && ViewDialog.values.codeSource != 'WRITE_OFF_CODE' &&
                                    <FormGroupField
                                        name="queryRules"
                                        label='码查询规则'
                                        placeholder="码查询规则"
                                        validate={this.validateQueryRules}
                                        required={ViewDialog ? ViewDialog.values ? ViewDialog.values.codeSource == 'PLATFORM_SHORT_CODE' || ViewDialog.values.codeSource == 'CODE_IMPORT' ? true : false : false : false}
                                        component={Input}
                                    /> : '' : ''}
                                <FormGroupField
                                    name="bankName"
                                    label='所属码库'
                                    placeholder="所属码库"
                                    validate={this.validatebankName}
                                    required={ViewDialog ? ViewDialog.values ? ViewDialog.values.codeSource == 'PLATFORM_SHORT_CODE' || ViewDialog.values.codeSource == 'CODE_IMPORT' ? true : false : false : false}
                                    component={NewSelect}
                                    style={{ width: 330 }}
                                    list={codedbList ? codedbList.content ? codedbList.content.map(item => { return { value: item.name, text: item.name } }) : [] : []}
                                />
                                {ViewDialog ? ViewDialog.values ? ViewDialog.values.codeSource == 'CODE_IMPORT' &&
                                    <FormGroupField
                                        name="regex"
                                        label='截取规则'
                                        placeholder="截取规则"
                                        validate={this.validateRegex}
                                        component={Input}
                                        required={ViewDialog ? ViewDialog.values ? ViewDialog.values.codeSource == 'CODE_IMPORT' ? true : false : false : false}
                                    /> : '' : ''
                                }
                            </FormGroupList >

                        </div>
                    </Dialog>}
                {(visible == 8) &&
                    <Dialog
                        title={visible == 8 ? '低额预警设置' : ''}
                        visible={visible}
                        onCancel={handleCancel}
                        onOk={handleSubmit(this.modalOk)}
                        style={{ width: 500 }}
                    >
                        <div>
                            <FormGroupList >
                                <FormGroupField
                                    name="warningValue"
                                    label='低码量预警'
                                    placeholder="低码量预警"
                                    validate={this.validateNumber}
                                    component={Input}
                                >
                                    <div style={{ color: '#666666', lineHeight: '18px', marginTop: 5 }}>低至设置值预警，不设置不预警</div>
                                </FormGroupField>
                            </FormGroupList>
                        </div>
                    </Dialog>
                }

                {(visible == 9) &&
                    <Dialog className='timeConfigForm'
                        title={visible == 9 ? '企业过期设置' : ''}
                        visible={visible}
                        onCancel={handleCancel}
                        onOk={handleSubmit(this.modalOk)}
                        style={{ width: 500 }}

                    >
                        <FormGroupList>
                            <FormGroupField
                                name={'expirationTime'}
                                label="过期时间"
                                component={DateTimePicker}
                            />
                            <FormGroupField
                                name={'remark'}
                                label="变更原因"
                                component={Textarea}
                                validate={this.validateRemark}
                            />
                        </FormGroupList>

                    </Dialog>}

            </div>
        )
    }
}



const ViewDialog = reduxForm({
    form: 'ViewDialog',
})(ViewDialog0);
export default ViewDialog;