import { ajax as ajaxJson } from 'rxjs/ajax';
import urls from '../api/urls';

export function ajax(config) {
    config.url = encodeURI(config.url);
    return ajaxJson(config)
}

export const fetchRequest = async () => {
    let response = {};
    try {
        response = await fetch(`${urls.baseUrl}/data/portal/gyl/secret`, {
            method: 'get'
        });
    } catch (error) {
        console.log('resError: ', JSON.stringify(error));
        return
    }
    
    const {status} = response;
    if (/2\d{2}$/.test(status)) {
        let responseJson = {};
        try {
        responseJson = await response.json();
            const {secret = ''} = responseJson;
            window.location.href = `${urls.cloudFactoryUrl}?encryptDesStr=${secret}`
        } catch (error) {
            console.log('error: ', error);
            return 
        }

    }

    
}
