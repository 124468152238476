import React from 'react';
import queryString from 'query-string';
import { reduxForm, FieldArray } from 'redux-form';
import { connect } from 'react-redux';
import { FormGroupList, Input, StaticFormGroup, DateTimePicker } from 'tyb';
import { FormGroupField } from 'tyb';
import './customFields.scss';
import { NewSelect } from 'ucode-components';
import { UploadLibrary} from 'ucode-components';

const selected = (message) => (value) => {
    return value && value !== '' ? undefined : message;
}

const maxLength = (max) => (value) => {
    return value && value.length > max ? `至多${max}字符` : undefined
}

const maxLength30 = maxLength(30);

const isNumber = (value) => {
    const pattr = /^[0-9]*$/;
    return pattr.test(value) || !value ? undefined : '请输入整数';
}

const max = (max) => (value) => {
    const myValue = parseInt(value);
    return !isNaN(myValue) && myValue > max ? `小于${max}` : undefined;
}

const max99999 = max(99999);


const validateLotteryNum3 = (value, values, props, name) => {
    let re = /^(\-)?([0-9]*|[0-9]*\.{1}\d{0,5})$/;
    let value2 = '';
    if (value || value == '0') {
        value2 = value + "";
    }
    if (value2 === '' || value2.trim().length == 0) {
        // return '不能为空';
    }
    else if (value2.trim() === '-') {
        return '请输入最多5位小数的数';
    }
    else if (!re.test(value2.trim())) {
        return '请输入最多5位小数的数';
    }
    else if (value2.trim() > 180) {
        return '经度范围-180~180';
    }
    else if (value2.trim() < -180) {
        return '经度范围-180~180';
    }
}
const validateLotteryNum4 = (value, values, props, name) => {

    let re = /^(\-)?([0-9]*|[0-9]*\.{1}\d{0,5})$/;
    let value2 = '';
    if (value || value == '0') {
        value2 = value + "";
    }
    if (value2 === '' || value2.trim().length == 0) {
        // return '不能为空';
    }
    else if (value2.trim() === '-') {
        return '请输入最多5位小数的数';
    }
    else if (!re.test(value2.trim())) {
        return '请输入最多5位小数的数';
    }

    else if (value2.trim() > 90) {
        return '纬度范围-90~90';
    }
    else if (value2.trim() < -90) {
        return '纬度范围-90~90';
    }

}

const FormTable = React.forwardRef(({ fields, zoneList, margin, self, url, disabled }, forwardRef) => {

    return <div className='box' style={{ display: 'inline-block', marginLeft: margin ? 0 : 78, marginBottom: 20 }}>
        <div className='box-head' >
            <div style={{ width: '20%' }}>字段Id</div>
            <div style={{ width: '30%' }}>字段名称</div>
            <div style={{ width: '40%' }}>操作</div>
        </div>
        {!fields.length && <div className="no-data">暂无数据</div>}
        {fields.map((item, index) => {
            const dictType = fields.get(index).dictType;

            return (
                <div className='box-body' key={index}>
                    <div style={{ overflow: 'visible', width: '20%' }}>
                        {fields.get(index).id}
                    </div>
                    <div style={{ overflow: 'visible', width: '30%' }}>
                        {fields.get(index).name}
                    </div>
                    <div style={{ overflow: 'visible', width: '40%' }}>

                        {['NUMBER', 'TXT', 'DATA'].includes(dictType) && <FormGroupField
                                name={`${item}.value`}
                                component={Input}
                                placeholder={dictType == 'NUMBER' ? '请输入数值' : '请输入'}
                                type="text"
                                validate={dictType == 'NUMBER' ? [isNumber, max99999] : [maxLength30]}
                                disabled={disabled}
                            >
                            </FormGroupField>}
                        {/* {['NUMBER', 'DATA'].includes(dictType) && <FormGroupField
                                name={`${item}.value`}
                                component={Input}
                                placeholder={dictType == 'NUMBER' ? '请输入数值' : '请输入'}
                                type="text"
                                validate={dictType == 'NUMBER' ? [isNumber, max99999] : [maxLength30]}
                            >
                            </FormGroupField>} */}
                        {
                            ['PICTURE'].includes(dictType) && <div ref={forwardRef} data-index={index}>
                                {fields.get(index).value && fields.get(index).value.indexOf('http') > -1 && <img src={fields.get(index).value} style={{ maxWidth:200,height: 40, margin: '5px 10px 5px 0' }}></img>}
                                <span className="custom-blue mr10" onClick={() => {
                                    self.setState({
                                        uploadLibraryData: {
                                            show: true,
                                            data: [],
                                            select: url
                                        }
                                    })
                                }}>上传图片</span>
                                <span className="custom-blue" onClick={() => {
                                    self.props.handleChange({ index })
                                    self.setState({
                                        url:''
                                    })
                                }}>清空</span>
                            </div>
                        }
                        {dictType == 'ENUM' &&
                            <FormGroupField
                                name={`${item}.value`}
                                component={NewSelect}
                                list={fields.get(index).dictItems ? fields.get(index).dictItems.map((item2) => { return { value: item2.id, text: item2.name } }) : []}
                                type="text"
                                placeholder="请选择"
                                disabled={disabled}
                            />
                        }

                        {dictType == 'TIME' &&
                            <FormGroupField
                                name={`${item}.value`}
                                component={DateTimePicker}
                                disabled={disabled}
                            />
                        }
                        {dictType == 'LONGITUDE_LATITUDE' &&
                            <React.Fragment>
                                <div className="longitude-style">
                                    <FormGroupField
                                        name={`${item}.value1`}
                                        component={Input}
                                        type="text"
                                        style={{ width: 155 }}
                                        placeholder="请输入经度"
                                        validate={validateLotteryNum3}
                                        disabled={disabled}
                                    />
                                </div>
                                <div>
                                    <FormGroupField
                                        name={`${item}.value2`}
                                        component={Input}
                                        type="text"
                                        style={{ width: 155 }}
                                        placeholder="请输入纬度"
                                        validate={validateLotteryNum4}
                                        disabled={disabled}
                                    />
                                </div>
                            </React.Fragment>
                        }

                        {dictType == 'PROVINCE' &&
                            <FormGroupField
                                name={`${item}.value`}
                                component={NewSelect}
                                list={zoneList.filter(key => key.level == 1)}
                                style={{ width: 105 }}
                                placeholder="请选择省"
                                disabled={disabled}
                            />
                        }

                        {dictType == 'CITY' &&
                            <React.Fragment>
                                <div>
                                    <FormGroupField
                                        name={`${item}.value1`}
                                        placeholder="请选择省"
                                        component={NewSelect}
                                        required={true}
                                        style={{ width: 105 }}
                                        list={zoneList.filter(item => item.level == 1)}
                                        onChange={e => {
                                            fields.get(index).value2 = '';
                                        }}
                                        disabled={disabled}
                                    >
                                    </FormGroupField>
                                </div>
                                <div>
                                    <FormGroupField
                                        name={`${item}.value2`}
                                        placeholder="请选择市"
                                        component={NewSelect}
                                        style={{ width: 105, marginLeft: 8 }}
                                        required={true}
                                        list={zoneList.filter(item => item.level == 2 && item.parentCode == fields.get(index).value1)}
                                        disabled={disabled}
                                    >
                                    </FormGroupField>
                                </div>
                            </React.Fragment>
                        }

                        {dictType == 'DISTRICT' &&
                            <React.Fragment>
                                <div>
                                    <FormGroupField
                                        name={`${item}.value1`}
                                        placeholder="请选择省"
                                        component={NewSelect}
                                        required={true}
                                        style={{ width: 105 }}
                                        list={zoneList.filter(item => item.level == 1)}
                                        onChange={e => {
                                            fields.get(index).value2 = '';
                                            fields.get(index).value3 = '';
                                        }}
                                        disabled={disabled}
                                    >
                                    </FormGroupField>
                                </div>
                                <div>
                                    <FormGroupField
                                        name={`${item}.value2`}
                                        placeholder="请选择市"
                                        component={NewSelect}
                                        style={{ width: 105, marginLeft: 8 }}
                                        required={true}
                                        list={zoneList.filter(item => item.level == 2 && item.parentCode == fields.get(index).value1)}
                                        onChange={e => {
                                            fields.get(index).value2 = '';
                                        }}
                                        disabled={disabled}
                                    >
                                    </FormGroupField>
                                </div>
                                <div style={{ display: 'inline-block', verticalAlign: 'top', }}>
                                    <FormGroupField
                                        name={`${item}.value3`}
                                        placeholder="请选择区"
                                        component={NewSelect}
                                        required={true}
                                        style={{ width: 105, marginLeft: 8 }}
                                        list={zoneList.filter(item => item.level == 3 && item.parentCode == fields.get(index).value2)}
                                        disabled={disabled}
                                    >
                                    </FormGroupField>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                </div>
            )
        })}
    </div>

})

@connect(
    state => {
        return {
            form: state.form,
            zoneList: state.zone.zoneList,
        };
    },
    {

    }
)

class CustomFields extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: '',
            uploadLibraryData: {
                show: false,
                data: [],
                select: '',
            }
        };

        this.ref = React.createRef();
    }


    uploadLibraryOk = (res) => {

        const index = this.ref.current.getAttribute('data-index');
        this.props.handleChange({ ...res, index })
        this.setState({
            url:res.url
        })
    }

    uploadLibraryCancel = () => {
        this.setState({ uploadLibraryData: { show: false, data: [] } })
    }

    Form = (props) => { return <FormTable ref={this.ref} {...props}></FormTable> }

    render() {
        const { name } = this.props;
        const { url, uploadLibraryData } = this.state;
        return <div className="customFields">
            <h4 style={{ fontSize: '14px', fontWeight: 'bold' }}>自定义字段</h4>
            <FieldArray name={name} component={this.Form} url={url} self={this} margin={this.props.margin} zoneList={this.props.zoneList} disabled={this.props.disabled} />
            {uploadLibraryData.show && <UploadLibrary
                uploadLibraryData={uploadLibraryData}
                uploadLibraryCancel={this.uploadLibraryCancel}
                uploadLibraryOk={this.uploadLibraryOk}
            >
            </UploadLibrary>}
        </div>
    }
}

export default CustomFields;