import React from 'react';
import { connect } from 'react-redux';
import { FieldArray, reduxForm } from 'redux-form'
import { Button, Dialog, FormGroupList, Input, SearchSelect, Select, TabList, StaticFormGroup, FormGroup, notification } from 'tyb';
import { FormGroupField } from 'tyb'
//actions
const uiActions = iceStarkData.store.get('commonAction')?.ui;
//图标
import AddIcon from '@/icons/AddIcon';
import '../productDetail.scss';

const validateName = (value) => {
    let value2 = '';
    if (value || value == '0') {
        value2 = value + "";
    }
    if (value2 === '' || value2.trim().length == 0) {
        return '不能为空';
    }
    else if (value2.trim().length > 5) {
        return '最多5个字符';
    }
}



const validateUserGetUpperTimes = (value) => {
    let re = /^[1-9][0-9]*$/
    let value2 = '';
    if (value || value == '0') {
        value2 = value + "";
    }

    if (value2 === '' || value2.trim().length == 0) {
        return '不能为空';
    }
    else if (value2.trim() === '0') {
        return '不能为0';
    }
    else if (value2.trim() === '') {
    }
    else if (!re.test(value2.trim())) {
        return '请输入数字值';
    }
    else if (value2.trim() > 9999999) {
        return '最大9999999';
    }
}



const table = ({ list, fields, dispatch, published ,values2}) => (
    <div>

        <div style={{ marginBottom: 20 }} >
            {/* <div style={{ display: 'inline-block', width: '50px', verticalAlign: 'top', color: '#888' }}> 奖品{index + 1}</div> */}

            <div className='box' style={{ display: 'inline-block' }}>
                <div className='box-head' >
                    <div>包装层级</div>
                    <div>包装名称</div>
                    <div>数量</div>
                    <div>操作</div>
                </div>
                {fields.map((item, index) => (
                    <div className='box-body' key={index}>
                        <div style={{ overflow: 'visible' }}>
                            {index + 1}{index == 0 && '（最小单位）'}
                        </div>
                        <div style={{ overflow: 'visible' }}>
                            <FormGroupField
                                name={`${item}.name`}
                                component={Input}
                                type="text"
                                placeholder="请输入"
                                validate={validateName}
                                // disabled={fields.get(index).publish}
                                style={{ width: 130 }}
                            />
                        </div>
                        <div >
                            {index != 0 && <FormGroupField
                                name={`${item}.subNum`}
                                component={Input}
                                type="text"
                                placeholder="请输入"
                                validate={validateUserGetUpperTimes}
                                // disabled={fields.get(index).publish}
                                style={{ width: 130 }}
                            />
                            }
                        </div>
                        <div>
                            {fields.length == 1 ? '' :
                                <span className='cblue cursorp' onClick={(e) => { if (fields.length == 1) { parentALL.remove(parentIndex) } else { fields.remove(index) } }} >移除</span>
                            }
                        </div>
                    </div>
                ))}

                <div className='box-body'>
                    <div style={{ width: 500 }}><AddIcon className='cursorp' onClick={(e) => { if (fields.length >= 20) { dispatch(uiActions.notification({ emsg: '最多20个', ecode: '500' })); } else { fields.push({}) } }}></AddIcon></div>
                </div>



            </div>
            {[...fields.getAll()].reverse().map((item, index) => {
                if (index != [...fields.getAll()].length - 1) {
                    return <span key={index} style={{ marginRight: 10, display: 'inline-block', fontSize: 12, color: '#666' }}>1{item.name}{item.subNum}{[...fields.getAll()].reverse()[index + 1] ? [...fields.getAll()].reverse()[index + 1].name : ''}</span>
                }
            })}
       <FormGroupList  style={{marginTop:20}} >
              <FormGroupField
                 name="used"
               
                  label='出库单位'
                //  placeholder="请选择"
                 placeholder="请选择"
                list={(values2.pack||[]).map((item)=>{return {value:item.name,id:item.name,text:item.name}})}
                 component={SearchSelect}
              >
                  </FormGroupField>
</FormGroupList >
        </div>
    </div>

)

@connect(
    (state) => {
        return {
            form:state.form.productForm,
        }
    },
    {},

)
class ProductModal0 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };

    }
    //保存修改
    modalOk = (value) => {
        const { onChangeAwardData, productModal } = this.props;
        let newarray = {
            ...productModal.data,
            ...value,

        }

        onChangeAwardData(newarray);

    };

    //取消修改
    modalCancel = () => {
        const { onAwardModalShow } = this.props;
        onAwardModalShow(false);
    }

    componentDidMount() {
        const { productModal } = this.props;

        if (productModal.data.packSpec) {
            if (productModal.data.packSpec.indexOf('[') >= 0) {
                let used='';
                if(JSON.parse(productModal.data.packSpec || '[{}]').filter(key=>key.used)[0]){
                    used={
                        text: JSON.parse(productModal.data.packSpec || '[{}]').filter(key=>key.used)[0].name,
                        value: JSON.parse(productModal.data.packSpec || '[{}]').filter(key=>key.used)[0].name
                    }
                   
                }
                this.props.initialize({ pack: JSON.parse(productModal.data.packSpec || '[{}]'),used })
            }

            else {
                this.props.initialize({ pack: [{}] })
            }
        }
        else{
            this.props.initialize({ pack: [{}] })
        }
        // }
        // else {
        // this.props.initialize({ pack: [{}] })

        // }


    }



    render() {
        const { productModal, handleSubmit,form } = this.props;
        return (
            <Dialog
                className={'productForm'}
                title={"包装设置"}
                visible={productModal.show}
                okText="确认"
                cancelText="取消"
                onOk={handleSubmit(this.modalOk)}
                onCancel={() => { this.modalCancel(false) }}
                shouldCloseOnOverlayClick={false}>

                <FieldArray name="pack" component={table} values2={form?form.values?form.values:{}:{}} />

            </Dialog>

        )
    }
}

const ProductModal = reduxForm({
    form: 'productForm',
})(ProductModal0);



export default ProductModal;
