import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import {
  Input,
  Button,
  DataTable,
  Dialog,
  Textarea,
  FormGroupList,
  StaticFormGroup,
  DatePicker,
  Radio
} from 'tyb'
import { NewSelect } from 'ucode-components'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { useDispatch, useSelector, useStyles } from '@/components/hooks'
import { FormGroupField } from 'tyb'
import { reduxForm } from 'redux-form'
import qs from 'query-string'
import $http from '@/utils/http'

function CreditInput(props) {
  const { value, onChange } = props

  return (
    <div style={{ fontSize: '12px' }}>
      <div style={{ marginBottom: '15px' }}>
        <Radio
          checked={value.type === '1'}
          onChange={() => {
            onChange({
              value: '',
              type: '1'
            })
          }}>
          <div style={{ display: 'inline-flex', alignItems: 'center' }}>
            <div className="mr-10" style={{ width: '3em' }}>
              调整至
            </div>
            <div style={{ flexGrow: 1 }}>
              <Input
                style={{ width: '100%' }}
                disabled={value.type !== '1'}
                value={value.type === '1' ? value.value : ''}
                onChange={e => {
                  if (/^\d+$/.test(e.target.value) || e.target.value === '') {
                    onChange({
                      ...value,
                      value: e.target.value
                    })
                  }
                }}
              />
            </div>
          </div>
        </Radio>
      </div>
      <div style={{ marginBottom: '15px' }}>
        <Radio
          checked={value.type === '2'}
          onChange={() => {
            onChange({
              value: '',
              type: '2'
            })
          }}>
          <div style={{ display: 'inline-flex', alignItems: 'center' }}>
            <div className="mr-10" style={{ width: '3em' }}>
              增加
            </div>
            <div style={{ flexGrow: 1 }}>
              <Input
                style={{ width: '100%' }}
                disabled={value.type !== '2'}
                value={value.type === '2' ? value.value : ''}
                onChange={e => {
                  if (/^[1-9]\d*$/.test(e.target.value) || e.target.value === '') {
                    onChange({
                      ...value,
                      value: e.target.value
                    })
                  }
                }}
              />
            </div>
          </div>
        </Radio>
      </div>
      <div style={{ marginBottom: '15px' }}>
        <Radio
          checked={value.type === '3'}
          onChange={() => {
            onChange({
              value: '',
              type: '3'
            })
          }}>
          <div style={{ display: 'inline-flex', alignItems: 'center' }}>
            <div className="mr-10" style={{ width: '3em' }}>
              减少
            </div>
            <div style={{ flexGrow: 1 }}>
              <Input
                style={{ width: '100%' }}
                disabled={value.type !== '3'}
                value={value.type === '3' ? value.value : ''}
                onChange={e => {
                  if (/^[1-9]\d*$/.test(e.target.value) || e.target.value === '') {
                    onChange({
                      ...value,
                      value: e.target.value
                    })
                  }
                }}
              />
            </div>
          </div>
        </Radio>
      </div>
      <div style={{ color: '#BBBBBB', paddingTop: '4px' }}>积分变更不会影响成长值</div>
    </div>
  )
}

function ShopCredit(props) {
  useStyles(`
    .ShopCreditForm .is-error .icon-valid-flag {
      display: none;
    }
  `)

  const { handleSubmit, history, appId, openId, initialize, title = '门店积分记录', authType = 'VERIFY' /** VERIFY:枚举，FRANCHISER:经销商 */ } = props
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [confirming, setConfirming] = useState(false)
  const formData = useSelector(state => state.form.ShopCreditForm?.values || {})

  const [pointTypes, setPointTypes] = useState([])
  useEffect(() => {
    $http({
      url: '/promotion/portal/prize-point-types/v1',
      params: {
        page: 0,
        size: 9999
      }
    }).then(res => {
      setPointTypes(
        res.content?.map(item => ({
          text: item.name,
          value: item.id
        }))
      )
    })
  }, [])

  const getList = useCallback(() => {
    if (!appId || !openId) return
    setLoading(true)
    $http({
      url: '/member-center/portal/manage/v1/pointDetails',
      params: {
        appId,
        openId
      }
    })
      .then(res => {
        setList(res?.content || [])
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [appId, openId])
  useEffect(getList, [getList])

  const selectedType = useMemo(() => {
    return list.find(item => item.pointType === formData.pointType)
  }, [list, formData.pointType])

  const headPointTypeRef = useRef(null)
  useEffect(() => {
    if (list[0]) {
      const { pointType } = list[0]
      headPointTypeRef.current = pointType
    }
  }, [list])
  useEffect(() => {
    if (visible) {
      initialize({
        pointType: headPointTypeRef.current,
        credit: {
          type: '1',
          value: ''
        }
      })
    }
  }, [visible])

  const onSubmit = useCallback(
    values => {
      const { credit } = values
      let amount
      if (credit.type === '1') {
        amount = Number(credit.value) - selectedType.validNumber
      } else if (credit.type === '2') {
        amount = Number(credit.value)
      } else if (credit.type === '3') {
        amount = -Number(credit.value)
      }
      setConfirming(true)
      $http({
        url: '/member-center/portal/manage/v1/point',
        method: 'put',
        data: {
          appId,
          openId,
          pointType: values.pointType,
          remark: values.remark,
          amount
        }
      })
        .then(() => {
          setConfirming(false)
          setVisible(false)
          getList()
        })
        .catch(() => {
          setConfirming(false)
        })
    },
    [appId, openId, getList, selectedType]
  )

  const columns = useMemo(() => {
    const column = [
      {
        key: 'pointName',
        dataIndex: 'pointName',
        title: '积分类型',
        render: (value, record) => (
          <div>{value || pointTypes.find(t => t.id === record.pointType)?.name}</div>
        )
      },
      {
        key: 'validNumber',
        dataIndex: 'validNumber',
        title: '可用数量'
      }
    ]
    if (list.length) {
      const head = list[0]
      if (head.expire && Object.keys(head.expire).length) {
        const arr = Object.entries(head.expire).map(([key, value]) => ({
          key: key,
          dataIndex: key,
          title: `${key}年过期积分`,
          render: () => <div>{value}</div>
        }))
        return column.concat(arr)
      }
    }
    return column
  }, [list])

  return (
    <div>
      <div className="mb-10">
        <Button
          className="mr-10"
          onClick={() => {
            setVisible(true)
          }}>
          积分调整
        </Button>
        <Button
          className="weak-button"
          onClick={() => {
            const str = qs.stringify({ appId, openId, title, authType })
            history.push(`/databasicData/datashop/credit-record/?${str}`)
          }}>
          积分记录
        </Button>
      </div>
      <div>
        <DataTable columns={columns} data={list} loading={loading} rowKey="id" />
      </div>

      {visible && (
        <Dialog
          style={{ width: '450px', minWidth: '450px' }}
          title="积分调整"
          visible={visible}
          confirming={confirming}
          onOk={handleSubmit(onSubmit)}
          onCancel={() => {
            setVisible(false)
          }}>
          <div className="ShopCreditForm">
            <FormGroupList>
              <FormGroupField
                label="积分类型"
                name="pointType"
                list={list.map(item => ({
                  text: item.pointName,
                  value: item.pointType
                }))}
                component={NewSelect}
                style={{ width: '300px' }}
              />
              <StaticFormGroup label="当前积分">{selectedType?.validNumber}</StaticFormGroup>
              <FormGroupField label="积分调整" name="credit" component={CreditInput} />
              <FormGroupField
                label="备注"
                name="remark"
                component={Textarea}
                style={{ width: '300px' }}
              />
            </FormGroupList>
          </div>
        </Dialog>
      )}
    </div>
  )
}

function validate(values) {
  const err = {}

  if (!values.pointType) {
    err.pointType = '请选择积分类型'
  }

  const credit = values.credit || {}
  if (!credit.value) {
    err.credit = '请填写积分'
  }

  if (values.remark?.length > 200) {
    err.remark = '长度不能超过200'
  }

  return err
}

export default reduxForm({
  form: 'ShopCreditForm',
  destroyOnUnmount: false,
  validate
})(withRouter(ShopCredit))
